import React, { useState, useRef } from 'react';
import { Typography, Card, Box, Button, Grid, Switch, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory } from "react-router-dom";

const pricingOptions = [
  {
    title: 'Growth-€25/month',
    description: 'For drivers racing individually  looking to  use AiMotor to improve their performance ',
    price: '€25/month',
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Join Unlimited Teams',
    ],
    buttonLabel: 'Start Now'
  },
  {
    title: 'Pro-€55/month',
    price: '€25/month',
    description: 'For drivers seeking to use the best AiMotor AI model  to compare and improve their performance within a single, exclusive group of drivers ',
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'Create One Team (up to 5 members)',
      'Multi Data Insertion',
      'Team Archive',
      'Team Analytics',
      'Team Management Dashboard',
    ],
    buttonLabel: 'Start Now'
  },
  {

    title: 'Team-€70/month',
    description: 'For drivers looking to use the best AiMotor AI model to compare and improve their performance with multiple and large groups of drivers',
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Join Unlimited Teams',
      'AI model AiMotor 2',
      'Multi Data Insertion',
      'Team Archive',
      'Team Analytics',
      'Advanced AI model',
      'Team Management Dashboard',
      'Create Unlimited teams',
      'Invite Unlimited team members',
    ],
    buttonLabel: 'Start Now'
  },
];

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#5d5fef',
  color: '#fff',
  borderRadius: '25px',
  padding: '10px 20px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: '#4a4cda',
  },
}));

const WhiteTick = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: '12px',
  height: '12px',
  borderLeft: '2px solid white',
  borderBottom: '2px solid white',
  transform: 'rotate(-45deg)',
  marginRight: '8px',
}));

const PricingComponent = () => {
  const [isYearlyBilling, setIsYearlyBilling] = useState(false);
  const [compareClicked, setCompareClicked] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tableRef = useRef(null);

  const handleCompareClick = () => {
    setCompareClicked(true);
    tableRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const history = useHistory();

  return (
    <Box
      sx={{
        width: '100vw',
        bgcolor: '#000',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
        margin: 0,
      }}
    >

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          p={2}
          borderRadius={2}
          width="100%"
        >
          <Typography variant="h3" style={{ color: '#5d5fef', fontWeight: 'bold', marginBottom: '16px' }}>
            Build with AiMotor
          </Typography>
          
          <Typography variant="h6" style={{ color: '#fff', marginBottom: '16px' }}>
            Customized plans to give you exactly what you need.         Try one of the three plans for free for the first month.
          </Typography>
        </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={2}
        borderRadius={2}
        width="100%"
        mt={-3}
      >

      </Box>
      
      <Grid container spacing={3} justifyContent="center" alignItems="stretch" sx={{ width: '100%', padding: isMobile ? '0 16px' : '0 80px' }}>
        {pricingOptions.map((option, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex' }}>
            <Card sx={{
              backgroundColor: option.title === 'Pro' ? '#fff' : '#1e1e2d',
              color: option.title === 'Pro' ? '#000' : '#fff',
              borderRadius: '15px',
              padding: 4,
              boxShadow: option.title === 'Pro' ? '0 4px 20px rgba(0,0,0,0.2)' : '0 4px 20px rgba(0,0,0,0.1), 0 0 15px rgba(93, 95, 239, 0.6)',
              '&:hover': {
                boxShadow: option.title === 'Pro' ? '0 6px 25px rgba(0,0,0,0.25)' : '0 6px 25px rgba(0,0,0,0.15), 0 0 20px rgba(93, 95, 239, 0.8)',
              },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              justifyContent: 'space-between',
              margin: 2,
              minHeight: '500px',
            }}>
              <Typography variant="h5" component="div" style={{ fontWeight: 'bold', marginBottom: '16px', fontSize: '1.5rem' }}>
                {option.title}
              </Typography>
              <Typography variant="body1" style={{ marginBottom: '16px', fontStyle: 'italic', fontSize: '1rem' }}>
                {option.description}
              </Typography>
              <ul style={{ padding: 0, listStyle: 'none', marginBottom: '16px', textAlign: 'left', fontSize: '1rem' }}>
                {option.features.map((feature, index) => (
                  <Typography component="li" key={index} style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                    <WhiteTick />{feature}
                  </Typography>
                ))}
              </ul>
              <StyledButton
                variant="contained"
                onClick={() =>  history.push("/sign-up") }
              >
                {option.buttonLabel}
              </StyledButton>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      {!isMobile && (
        <Button
          variant="outlined"
          onClick={handleCompareClick}
          sx={{
            backgroundColor: compareClicked ? '#4a4cda' : '#5d5fef',
            color: '#fff',
            borderRadius: '25px',
            padding: '10px 20px',
            textTransform: 'none',
            fontWeight: 'bold',
            fontSize: '1rem',
            margin: '20px 0',
            '&:hover': {
              backgroundColor: '#4a4cda',
            },
            marginTop: "60px",
            marginBottom: "60px"
          }}
        >
          Compare Plans
        </Button>
      )}

      {!isMobile && (
        <Box ref={tableRef} id="comparison-table" sx={{ overflowX: 'auto', width: '80%', mt: 4, mx: 'auto', my: 4 }}>
          <table style={{ width: '100%', borderCollapse: 'collapse', color: '#fff' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #444', padding: '10px', backgroundColor: '#2d2d44' }}></th>
                {pricingOptions.map((option, index) => (
                  <th key={index} style={{ border: '1px solid #444', padding: '10px', backgroundColor: '#2d2d44', textAlign: 'center' }}>
                    {option.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4} style={{ backgroundColor: '#333', color: '#fff', padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>Generative AI</td>
              </tr>
              {['AI model AiMotor 1', 'AI model AiMotor 2'].map((feature, featureIndex) => (
                <tr key={featureIndex}>
                  <td style={{ border: '1px solid #444', padding: '10px' }}>{feature}</td>
                  {pricingOptions.map((option, index) => (
                    <td key={index} style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>
                      {option.features.includes(feature) ? <WhiteTick /> : ''}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td colSpan={4} style={{ backgroundColor: '#333', color: '#fff', padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>Data Management</td>
              </tr>
              {['Data Insertion', 'Data Archive', 'Setup Analytics', 'Multi Data Insertion', 'Team Archive', 'Team Analytics'].map((feature, featureIndex) => (
                <tr key={featureIndex}>
                  <td style={{ border: '1px solid #444', padding: '10px' }}>{feature}</td>
                  {pricingOptions.map((option, index) => (
                    <td key={index} style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>
                      {option.features.includes(feature) ? <WhiteTick /> : ''}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td colSpan={4} style={{ backgroundColor: '#333', color: '#fff', padding: '10px', textAlign: 'center', fontWeight: 'bold' }}>Racing Team</td>
              </tr>
              {['Personal Space', 'Join Unlimited Teams', 'Create One Team (up to 5 members)', 'Create Unlimited teams', 'Invite Unlimited team members'].map((feature, featureIndex) => (
                <tr key={featureIndex}>
                  <td style={{ border: '1px solid #444', padding: '10px' }}>{feature}</td>
                  {pricingOptions.map((option, index) => (
                    <td key={index} style={{ border: '1px solid #444', padding: '10px', textAlign: 'center' }}>
                      {option.features.includes(feature) ? <WhiteTick /> : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </Box>
  );
};

export default PricingComponent;
