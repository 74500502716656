import './components.css';

import { Box, Button, Chip, Container, Grid, Stack, Typography } from '@mui/material';

import { Link } from "react-router-dom";
import React from 'react';
import myUserStore from 'stores/UserStore';
import { observer } from 'mobx-react-lite';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function SecondPartHome() {
    const isLoggedIn = myUserStore.isLoggedIn
  
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <Box className="second-part-home">
            <Container maxWidth={false} className='container-lg'>
                <Stack direction="row" spacing={1} mb={10} justifyContent={'center'}>
                    <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" color='primary' clickable /*sx={{color: 'white' }}*/ />
                    <Chip label="For Racing Teams" component="a" href='#For Team Managers' variant="outlined" clickable sx={{ color: 'white' }} />
                </Stack>
                
                <Grid container rowSpacing={{ xs: '160px', lg: '200px'}}>
                    {/* section 1 */}
                    <Grid item container spacing={{ xs: 6, lg: 10 }} alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs={12} sm={10} lg={6}>
                            <Box textAlign={{ xs: 'center', lg: 'left' }} maxWidth={{ lg: '90%' }} mr="auto">
                                <Typography variant='h4' color="white" fontWeight={700} my={{ xs: '20px', lg: '40px'}}>
                                    <span class="gradient-title"> Get answers. Find inspiration. Be faster. </span> Easy to try. Just ask and AiMotor will help
                                </Typography>
                                <div>
                                    <p style={{ color: 'white', fontWeight: 300 }}> Finds for you the Best Setup for Every Scenario. Helps you improve your driving style.
                                        It provides you with a step-by-step guide to master every Go-Kart adjustment. Manages your data for you.
                                    </p>
                                    <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center', lg: 'start'}} hidden={!isDesktop}>
                                        <Link to="/sign-up" className="insertdata">
                                            <Button color="light" variant="text" onClick={() => console.log("hey")}>
                                                Start Now
                                            </Button>
                                        </Link>
                                        <Link to="/ai-product" className="insertdata">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Learn More
                                            </Button>
                                        </Link>
                                    </Stack>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={6}>
                            <img src={"videos/CHAT.gif"} alt={"gif"} style={{ width: "100%" }} className="content-gif" />
                            <Stack direction="row" spacing={1} className="hero-btns" justifyContent={"center"} hidden={isDesktop}>
                                <Link to="/sign-up" className="insertdata">
                                    <Button color="light" variant="text" onClick={() => console.log("hey")}>
                                        Start Now
                                    </Button>
                                </Link>
                                <Link to="/ai-product" className="insertdata">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Learn More
                                    </Button>
                                </Link>
                            </Stack>
                        </Grid>
                    </Grid>

                    {/* section 2 */}
                    <Grid item container spacing={{ xs: 6, lg: 10 }} alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs={12} sm={10} lg={6} order={{ xs: 2, lg: 1}}>
                            <img src={"images/insert_home.jpg"} alt={"gif"} style={{ width: "100%" }} className="content-gif" />
                            <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center', lg: 'start'}} hidden={isDesktop}>
                                <Link to="/sign-up" className="insertdata">
                                    <Button color="light" variant="text" onClick={() => console.log("hey")}>
                                        Start Now
                                    </Button>
                                </Link>
                                <Link to="/insert-product" className="insertdata">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Learn More
                                    </Button>
                                </Link>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={6} order={{ xs: 1, lg: 2}}>
                            <Box textAlign={{ xs: 'center', lg: 'left' }} maxWidth={{ lg: '90%' }} ml="auto">
                                <Typography variant='h4' color="white" fontWeight={700} my={{ xs: '20px', lg: '40px'}}>
                                    Fuel the model with your data <span class="gradient-title"> Quickly and Easily</span>
                                </Typography>
                                <div>
                                    <p style={{ color: 'white', fontWeight: 300 }}> After a racing session, you can enter the setup you used. Each data entry is divided into three parts: Engine, Chassis, and Driving Experience.
                                        With autocompilation, it will only take a few seconds
                                    </p>
                                    <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center', lg: 'start'}} hidden={!isDesktop}>
                                        <Link to="/sign-up" className="insertdata">
                                            <Button color="light" variant="text" onClick={() => console.log("hey")}>
                                                Start Now
                                            </Button>
                                        </Link>
                                        <Link to="/insert-product" className="insertdata">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Learn More
                                            </Button>
                                        </Link>
                                    </Stack>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                    
                    {/* section 3 */}
                    <Grid item container spacing={{ xs: 6, lg: 10 }} alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs={12} sm={10} lg={6}>
                            <Box textAlign={{ xs: 'center', lg: 'left' }} maxWidth={{ lg: '90%' }} mr="auto">
                                <Typography variant='h4' color="white" fontWeight={700} my={{ xs: '20px', lg: '40px'}}>
                                <span class="gradient-title"> Keep track and manage your setups. </span> With Confidence
                                </Typography>
                                <div>
                                    <p style={{ color: 'white', fontWeight: 300 }}> Effortlessly store and access your track data with our intuitive and advanced archive.You can delete sessions you don't want to see.You can modify fields that were entered incorrectly</p>
                                    <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center', lg: 'start' }} hidden={!isDesktop}>
                                        <Link to="/sign-up" className="insertdata">
                                            <Button color="light" variant="text" onClick={() => console.log("hey")}>
                                                Start Now
                                            </Button>
                                        </Link>
                                        <Link to="/archive-product" className="insertdata">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Learn More
                                            </Button>
                                        </Link>
                                    </Stack>
                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={6}>
                            <img src={"images/archive.jpg"} alt={"gif"} style={{ width: "100%" }} className="content-gif" />
                            <Stack direction="row" spacing={1} className="hero-btns" justifyContent={'center'} hidden={isDesktop}>
                                <Link to="/sign-up" className="insertdata">
                                    <Button color="light" variant="text" onClick={() => console.log("hey")}>
                                        Start Now
                                    </Button>
                                </Link>
                                <Link to="/archive-product" className="insertdata">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Learn More
                                    </Button>
                                </Link>
                            </Stack>
                        </Grid>
                    </Grid>

                    {/* section 4 */}
                    <Grid item container spacing={{ xs: 6, lg: 10 }} alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs={12} sm={10} lg={6} order={{ xs: 2, lg: 1}}>
                          
                                <img src={"images/barcharts.jpg"} alt={"gif"} style={{ width: "100%" }} className="content-gif" />
                      
                            <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center', lg: 'start'}} hidden={isDesktop}>
                                <Link to="/sign-up" className="insertdata">
                                    <Button color="light" variant="text" onClick={() => console.log("hey")}>
                                        Start Now
                                    </Button>
                                </Link>
                                <Link to="/analytics-product" className="insertdata">
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Learn More
                                    </Button>
                                </Link>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={10} lg={6} order={{ xs: 1, lg: 2}}>
                            <Box textAlign={{ xs: 'center', lg: 'left' }} maxWidth={{ lg: '90%' }} ml="auto">
                                <Typography variant='h4' color="white" fontWeight={700} my={{ xs: '20px', lg: '40px'}}>
                                    Analyze your setups using bar charts and tables <span class="gradient-title"> for clearer insights and comparisons </span>
                                </Typography>
                                <div>
                                    <p style={{ color: 'white', fontWeight: 300 }}>
                                        The analysis is divided into three parts: Chassis, Engine, and Pressure
                                        Each part is modular: the user can choose which fields to analyze and which to exclude.
                                    </p>
                                    <Stack direction="row" spacing={1} className="hero-btns" justifyContent={{ xs: 'center', lg: 'start'}} hidden={!isDesktop}>
                                        <Link to="/sign-up" className="insertdata">
                                            <Button color="light" variant="text" onClick={() => console.log("hey")}>
                                                Start Now
                                            </Button>
                                        </Link>
                                        <Link to="/analytics-product" className="insertdata">
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Learn More
                                            </Button>
                                        </Link>
                                    </Stack>
                                </div>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
    

                {/* non credo abbia senso ripeterlo pure qui, anche perche riporta l'utente nuovamente su */}
                <Stack direction="row" spacing={1} sx={{ flexDirection: "row", justifyContent: "center", marginTop: 10, marginBottom: 2 }}>
                    <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" clickable sx={{ color: 'white' }} />
                    <Chip label="For Racing Teams" id="For Team Managers" component="a" href='#For Team Managers' variant="outlined" color='primary' clickable />
                </Stack>
            </Container>
        </Box>
    );
}

export default observer(SecondPartHome);