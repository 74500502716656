import * as React from 'react';

import { FormControl, Grid, IconButton, InputLabel, Select } from '@mui/material';
import Mmenu, { MenuProps } from '@mui/material/Menu'
import { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import EditableCell from "../EditableCell";
import EditableCellNumeric from "../EditableCellNumeric";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuItem from '@mui/material/MenuItem';
import { Modal } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import RemoveIcon from '@mui/icons-material/Remove';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import axios from "axios";
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { toast } from "react-toastify";

function PageHeader({ drivers, onChange = () => { }, race, day, competitions, onDayChange = () => { }, onRaceChange = () => { }, competitionLoading }) {
  const [racingData, setRacingData] = useState([]);
  const [driver, setDriver] = useState([]);
  const [fullDrivers, setFullDriver] = useState([]);
  console.log("Drivers", drivers)
  const [emailName, setEmailName] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDay, setSelectedDay] = useState(false);


  const handleChange = (event) => {
    // If multiple options can be selected, event.target.value will be an array
    const selectedDrivers = event.target.value;
    onChange(selectedDrivers)
    setDriver(selectedDrivers);
    console.log(selectedDrivers)



  };
  
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedDay(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSelectDay = (day) => {
    console.log('Selected day:', day);
    onDayChange(day);
    handleMenuClose();
  };
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;

  const racingDays = [
    { name: 'First Racing Day', value: 1 },
    { name: 'Second Racing Day', value: 2 },
    { name: 'Third Racing Day', value: 3 },
    { name: 'Fourth Racing Day', value: 4 },
    { name: 'Fifth Racing Day', value: 5 },
    { name: 'Sixth Racing Day', value: 6 },
    { name: 'Seventh Racing Day', value: 7 },
  ];


  

  return (

    <div>


      <Grid container columnSpacing={3} sx={{ alignItems: "center" }} >
        <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
          <Typography sx={{ fontSize: 40 }} > Archive</Typography>
        </Grid>
        <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
          <div className="frame1" style={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: '10px', width: '100%' }}>
            {/*<FormControl fullWidth sx={{ textAlign: 'left', background: 'rgba(0, 0, 0, 0.00)', minWidth: 100 }}>

      <Typography sx={{ fontSize: 33, marginBottom: 4, marginTop: 2 }} className="text-wrapper">Archive</Typography>
      <Grid container direction="row" columnSpacing={4} rowSpacing={2} sx={{ marginBottom: 3 }}>

        <Grid item lg={showPersonalButton ? 12 : 6} md={showPersonalButton ? 12 : 6} sm={showPersonalButton ? 12 : 6} xs={12}>
          <Box className="frame1" sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: '10px', width: '100%' }}>

            {/*<FormControl fullWidth sx={{ textAlign: 'left', background: 'rgba(0, 0, 0, 0.00)' }}>
              <InputLabel id="race-select-label">RACE</InputLabel>
              <Select
                labelId="race-select-label"
                fullWidth
                id="race-select"
                value={race}
                onChange={(e) => {
                  setRace(e.target.value);
                }}
                label="Race"
              >
                {competitions.length === 0 ? (
                  <MenuItem value="">Please Insert a Competition</MenuItem>
                ) : (
                  <>
                    <MenuItem value="">Select Race</MenuItem>
                    {competitions.map((competition) => (
                      <MenuItem key={competition.id} value={competition.id}>
                        {competition.competition} ({competition.track})
                      </MenuItem>
                    ))}
                  </>
                )}
              </Select>
            </FormControl>*/}
            <FormControl fullWidth sx={{ textAlign: 'left', background: 'rgba(0, 0, 0, 0.00)' }}>
              <InputLabel id="race-select-label">RACE</InputLabel>
              <Select
                labelId="race-select-label"
                id="race-select"
                value={race}
                onChange={(e) => {{
                  onRaceChange(e.target.value);
                  setDriver([]);
                }
                 
                }}
                label="Race"
              >
                {!competitionLoading ?
                  (competitions.length === 0 ?
                    <MenuItem value="">Please Insert a Competition</MenuItem>
                    :
                    [
                      <MenuItem key="select-race" value="">Select Race</MenuItem>,
                      ...competitions.map((competition) => (
                        <MenuItem key={competition.id} value={competition.id}>
                          {competition.competition} ({competition.track})
                        </MenuItem>
                      ))
                    ]

                  ) : (
                    <MenuItem value="" disabled>
                      <Skeleton variant="rectangular" sx={{ borderRadius: '8px', marginLeft: 0.5 }} height={30} />
                    </MenuItem>
                  )}
              </Select>
            </FormControl>
            <Button
              className="button"
              aria-controls="number-menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              style={{ whiteSpace: 'nowrap', color: (!day ? 'red' : 'green') }}
            >
              <CalendarMonthIcon className="calendar-month" />
            </Button>
            <Mmenu
              id="number-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {racingDays.map((racingDay) => (
                <MenuItem key={racingDay.value} onClick={() => handleSelectDay(racingDay.value)}>
                  {racingDay.name}
                </MenuItem>
              ))}
            </Mmenu>
          </div>

        </Grid>
        {!showPersonalButton && (<Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>

          <div className="autocomplete-wrapper" style={{ width: "100%" }}>
            <div className="autocomplete" style={{ width: '100%' }}>
              <FormControl sx={{}} style={{ width: '100%' }}>
                <InputLabel id="demo-multiple-chip-label">Driver</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={driver}
                  label="Driver"
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>

                      {selected.map((value) => {
                        const selectedName = drivers.find(dataItem => dataItem.email === value);
                        const name = selectedName ? selectedName.surname : '';
                        return (
                          <Chip key={value} label={name} />
                        );
                      })}
                    </Box>
                  )}
                >
                  {drivers.length === 0 ? (
                    <MenuItem key="no-driver" value="" disabled>
                    No driver has inserted yet!
                  </MenuItem>
                  ) : (
                    drivers.map((driver) => (
                      <MenuItem key={driver.id} value={driver.email}>
                        {driver.surname}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </Grid>)}

      </Grid>
    </div>

  );
}


function Row(props) {
  const { rows, onDelete, getDataArchive } = props;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(-1);
  const [show, setShow] = useState(false);
  const selectedTeam = myUserStore.getSelectedTeam;

  const updateValue = (value, field, id, team_id) => {
    axios
      .post(
        `https://aimotor.eu/api/change_data/?value=${value}&field=${field}&id=${id}&team_id=${selectedTeam.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        toast.success("Value updated successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        getDataArchive();
      })
      .catch((error) => {
        toast.error("Failed to update the value!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const deleteValue = (id, team_id) => {
    axios
      .post(
        `https://aimotor.eu/api/delete_data/?id=${id}&team_id=${selectedTeam.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        toast.success("Entry deleted successfully!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        handleClose();
        onDelete(id); // Update the state in the parent component
      })
      .catch((error) => {
        toast.error("Failed to delete the entry!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const mapperYN = (value) => {
    switch (value) {
      case 0:
        return "No";
      case 1:
        return "Yes";
      default:
        return "Unknown";
    }
  };
  const isDriver = selectedTeam.role == "DRV"
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));
  const mySurname = parsedUserData.surname
 
  
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" }, cursor: 'pointer' }} onClick={() => setOpen(!open)}>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => deleteValue(id)}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {rows[0].surname}
        </TableCell>
      </TableRow>
      <TableRow sx={{ "& > *": { borderBottom: "none" } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell> </TableCell>
                    <TableCell>Session Number</TableCell>
                    <TableCell>Lap Time</TableCell>
                    <TableCell align="right">Chassis Number</TableCell>
                    <TableCell align="right">Engine Number</TableCell>
                    <TableCell>Tires New</TableCell>
                    <TableCell>Hot Pressure</TableCell>
                    <TableCell align="right">Cold Pressure</TableCell>
                    <TableCell align="right">Sprocket</TableCell>
                    <TableCell>Pinion</TableCell>
                    <TableCell align="right">Min RPM</TableCell>
                    <TableCell align="right">Camber</TableCell>
                    <TableCell align="right">Caster</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((rowData, index) => (
                    <TableRow key={index} sx={{ "& > *": { borderBottom: "none" } }}>
                      <TableCell style={{ verticalAlign: "middle", borderBottom: "none" }}>
                        <RemoveIcon
                          size="1.5rem"
                          className="remove-icon"
                          onClick={() => handleShow(rowData.id)}
                        />
                      </TableCell>
                      {!(!isDriver || mySurname == rows[0].surname) && (<>
                        <TableCell>{rowData.session_number}</TableCell>
                        <TableCell>{rowData.laptime}</TableCell>
                        <TableCell align="right">{rowData.chassis_number}</TableCell>
                        <TableCell>{rowData.engine_number}</TableCell>
                        <TableCell align="right">{mapperYN(rowData.tires_new)}</TableCell>
                        <TableCell>{rowData.pressure_hot}</TableCell>
                        <TableCell align="right">{rowData.pressure_cold}</TableCell>
                        <TableCell>{rowData.crown}</TableCell>
                        <TableCell align="right">{rowData.pinion}</TableCell>
                        <TableCell>{rowData.min_rpm}</TableCell>
                        <TableCell align="right">{rowData.camber}</TableCell>
                        <TableCell align="right">{rowData.caster}</TableCell></>
                      )}

                      {(!isDriver || mySurname == rows[0].surname) && ( <><EditableCellNumeric
                        value={rowData.session_number}
                        onSave={updateValue}
                        field="session_number"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCell
                        value={rowData.laptime}
                        onSave={updateValue}
                        field="laptime"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCell
                        align="right"
                        value={rowData.chassis_number}
                        onSave={updateValue}
                        field="chassis_number"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCell
                        value={rowData.engine_number}
                        onSave={updateValue}
                        field="engine_number"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCell
                        value={mapperYN(rowData.tires_new)}
                        onSave={updateValue}
                        field="tires_new"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCellNumeric
                        value={rowData.pressure_hot}
                        onSave={updateValue}
                        field="pressure_hot"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCellNumeric
                        align="right"
                        value={rowData.pressure_cold}
                        onSave={updateValue}
                        field="pressure_cold"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCellNumeric
                        value={rowData.crown}
                        onSave={updateValue}
                        field="crown"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCellNumeric
                        align="right"
                        value={rowData.pinion}
                        onSave={updateValue}
                        field="pinion"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCellNumeric
                        value={rowData.min_rpm}
                        onSave={updateValue}
                        field="min_rpm"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCellNumeric
                        align="right"
                        value={rowData.camber}
                        onSave={updateValue}
                        field="camber"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      />
                      <EditableCellNumeric
                        align="right"
                        value={rowData.caster}
                        onSave={updateValue}
                        field="caster"
                        id={rowData.id}
                        style={{ borderBottom: "none" }}
                      /></>)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}




const CollapsibleTable = () => {
  const [drivers, setDrivers] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [racingData, setRacingData] = useState([]);
  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  const [competitions, setCompetitions] = useState([]);
  const [competitionLoading, setCompetitionLoading] = useState(false);

  const onDriverChange = (selectedDrivers) => {
    setSelectedDrivers(selectedDrivers);
  };

  const onDayChange = (selectedDay) => {
    setDay(selectedDay);
    console.log("selected Day", day);
  };

  const onRaceChange = (race) => {
    setRace(race);
    setSelectedDrivers([]);
    console.log("selected race", race);
    
  };
  console.log("selected drivers", selectedDrivers);

  // const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;

  useEffect(() => {
    getDrivers(false);
  }, [, selectedTeam]);

  const getDrivers = async (filter, racingData) => {
    axios
      .get(`https://aimotor.eu/api/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        if (showPersonalButton) {
          setDrivers(res.data);
          setSelectedDrivers([res.data[0].email]);
        } else {
          setDrivers(res.data);
        }
        
        if (filter) {
          const racingDataEmails = racingData.map((item) => item.email);
          const filteredDrivers = res.data.filter((driver) => racingDataEmails.includes(driver.email));
          setDrivers(filteredDrivers);
          console.log("filtered drivers", filteredDrivers);
        }
        console.log("Drivers API Response:", drivers);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  };

  // const rows = racingData.map(driver => createData(driver.surname, driver.surname));

  const groupedData = racingData.reduce((acc, curr) => {
    if (!acc[curr.email]) {
      acc[curr.email] = [];
    }
    acc[curr.email].push(curr);
    return acc;
  }, {});

  console.log("Grouped Data:", groupedData);

  useEffect(() => {
    setCompetitionLoading(true);
    const fetchCompetitions = async () => {
      try {
        const response = await axios.get(`https://aimotor.eu/api/get_competitions/?team_id=${selectedTeam.id}`);
        const data = response.data;
        console.log("Competitions API Response:", data);
        setCompetitions(data); // Set competitions state with the data
        setCompetitionLoading(false);
      } catch (error) {
        setCompetitionLoading(false);
        console.error("Error fetching competitions:", error);
      }
    };

    fetchCompetitions(); // Call the fetchCompetitions function when the component mounts
  }, [, selectedTeam]);


  const getDataArchive =  () => {
    axios
      .get("https://aimotor.eu/api/get_data_archive", {
        params: {
          email: selectedDrivers.join(","),
          race,
          day,
          team_id: selectedTeam.id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        console.log("API Response:", data); // Log the API response
        if (data !== null) {
          // Calculate statistics
          console.log("RacingData", data); // Log dataset with statistics
          setRacingData(data); // Update state with dataset with statistics
          if(selectedDrivers.length == 0){
            getDrivers(true, data);
          }
        }
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  }
  useEffect(() => {
    getDataArchive();
  }, [selectedDrivers, race, day]);

  const handleDeleteRow = (id) => {
    setRacingData((prevRacingData) =>
      prevRacingData.filter((row) => row.id !== id)
    );
  };

  return (
    <React.Fragment>
      <PageHeader
        drivers={drivers}
        onChange={onDriverChange}
        race={race}
        onDayChange={onDayChange}
        onRaceChange={onRaceChange}
        day={day}
        competitions={competitions}
        competitionLoading={competitionLoading}
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {/* Add table headers here if needed */}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(groupedData).map((email, index) => (
              <Row key={index} rows={groupedData[email]} onDelete={handleDeleteRow} getDataArchive={getDataArchive}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default observer(CollapsibleTable);