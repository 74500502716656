import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: '#000',
  color: '#fff',
  padding: theme.spacing(4),
  textAlign: 'center',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflowX: 'hidden',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: '#7A74E0',
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  margin: '1rem 0',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
}));

const StepperContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  marginTop: '2rem',
}));

const StepButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
  paddingBottom: '10px',
}));

const StepButton = styled(Button)(({ theme, active }) => ({
  color: active ? '#000' : '#fff',
  backgroundColor: active ? '#7A74E0' : 'transparent',
  border: '1px solid #fff',
  borderRadius: '20px',
  padding: '0.5rem 1rem',
  margin: theme.spacing(1),
  transition: 'background-color 0.3s, color 0.3s',
  whiteSpace: 'normal', // Changed to allow text to wrap
  textAlign: 'center',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:hover': {
    backgroundColor: '#7A74E0',
    color: '#000',
  },
}));

const Line = styled('div')(({ theme, activeStep }) => ({
  position: 'absolute',
  bottom: 0,
  left: '5%',
  width: '90%',
  height: '2px',
  background: `linear-gradient(to right, #7A74E0 ${activeStep * (100 / (steps.length - 1))}%, #fff ${activeStep * (100 / (steps.length - 1))}%)`,
  transition: 'background 0.3s ease-in-out',
}));

const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  marginTop: '2rem',
  overflowX: 'auto',
  padding: '1rem 0',
  scrollSnapType: 'x mandatory',
  '-webkit-overflow-scrolling': 'touch',
  height: 'auto',
  flex: '0 0 100%',
  [theme.breakpoints.up('md')]: {
    flex: '0 0 50%',
  },
}));

const CustomCard = styled(Card)(({ theme }) => ({
  minWidth: '100%',
  maxWidth: '100%',
  flex: '0 0 auto',
  margin: '0 1rem',
  backgroundColor: '#1c1c1c',
  color: '#fff',
  border: '1px solid #444',
  borderRadius: '12px',
  scrollSnapAlign: 'center',
  height: 'auto',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    minWidth: '50%',
    maxWidth: '50%',
  },
}));

const steps = [
  { label: 'AI', content: 'Optimize Your Performance: The model is trained with all team data, ensuring more accurate advice.', imageUrl: 'images/chat_home.jpg' },
  { label: 'Insertion', content: 'Enter Your Data Faster: You don’t need to enter your data directly; the team manager can do it for you. By selecting multiple drivers in the Insertion component, a lot of data can be entered with just one click', imageUrl: 'images/MULTYinsert.jpg' },
  { label: 'Archive', content: 'Access All Your Teammates Setups: In the archive component, for every competition, you will be able to see not only your setup but also those used by each member of the community', imageUrl: 'images/team_arch.jpg' },
  { label: 'Analytics', content: 'Analyze and Compare Your Setups with Your Teammates: By selecting multiple drivers in the setup analytics component, you can improve by understanding your teammates setups and changes', imageUrl: 'images/analytics.jpg' },
];

export default function HorizontalStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const cardRefs = React.useRef([]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio > 0.75) {
            const index = cardRefs.current.indexOf(entry.target);
            setActiveStep(index);
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: [0.25, 0.5, 0.75, 1] }
    );

    cardRefs.current.forEach((card) => {
      if (card) {
        observer.observe(card);
      }
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) {
          observer.unobserve(card);
        }
      });
    };
  }, []);

  const handleStepClick = (index) => {
    cardRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    setActiveStep(index);
  };

  return (
    <Container>
      <Title>The Four Advantages of Building Teams with AiMotor</Title>
      <Subtitle>Teams go faster with AiMotor: Sharing data between members makes the difference.</Subtitle>
      <StepperContainer>
        <StepButtonsContainer>
          {steps.map((step, index) => (
            <StepButton
              key={step.label}
              active={activeStep === index}
              onClick={() => handleStepClick(index)}
            >
              {step.label}
            </StepButton>
          ))}
        </StepButtonsContainer>
        <Line activeStep={activeStep} />
      </StepperContainer>
      <CardContainer>
        {steps.map((step, index) => (
          <CustomCard key={step.label} ref={(el) => (cardRefs.current[index] = el)}>
            <CardContent>
              <Typography variant="h6" component="div">{step.label}</Typography>
              <Typography variant="body2">{step.content}</Typography>
              <img src={step.imageUrl} alt={step.label} style={{ marginTop: '1rem', width: '100%', borderRadius: '12px', marginTop:'10px' }} />
            </CardContent>
          </CustomCard>
        ))}
      </CardContainer>
    </Container>
  );
}
