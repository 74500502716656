import { Box, Button, Card, CardContent, Chip, List, ListItem, ListItemIcon, ListItemText, Skeleton, Stack, Typography } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check'

const Plan = ({
  plan,
  period = "MONTH",
  isActive,
  loading = false,
  onClick = () => {},
  ...props
}) => {
  
  const handleClick = (title, price) => {    
    onClick(title, price)
  }

  return (
    <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      borderRadius: 4,
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
      py: 2,
      px: 2,
      backgroundColor: isActive ? '#fff7e0' : '#fff',
      ...(props.sx || {})
    }}>
      <CardContent>
        <Stack direction={"row"} justifyContent={'space-between'}>
          <Typography variant="h6" component="div" letterSpacing={1.2} style={{ color: plan.titleColor, fontWeight: 'bold' }}>
            {plan.title.toUpperCase()}
          </Typography>
          <Chip {...(plan?.chip || {})} size={'small'} hidden={!plan?.chip?.label} sx={{ textTransform: 'uppercase', lineHeight: 1, fontSize: 10 }} />
        </Stack>
        <Box mt={0.5}>
          <Typography variant="h3" component="span" color="black" fontWeight={'bold'} >
            {plan.currency}{period === "MONTH" ? plan.priceNumber : plan.yearPriceNumber}/
            <Typography variant="body1" component="span" color="textSecondary">{period === "MONTH" ? 'month' : 'year'} </Typography>
          </Typography>
        </Box>
        <List dense size="sm" sx={{ p: 0, mt: 2 }}>
        {plan.features.map((feature, index) => (
          <ListItem key={index} sx={{ px: 0, py: { md: 0, lg: 0.5} }}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <CheckIcon color='success' />
            </ListItemIcon>
            <ListItemText primary={feature} />
          </ListItem>
        ))}
        </List>
      </CardContent>
      <Box px={2} pt={0} pb={1} display="flex" justifyContent="center">
        <Skeleton variant="rounded" width={120} height={30} hidden={!loading} />
        <Button
          hidden={loading}
          variant="contained"
          fullWidth
          color="primary"
          size={'large'}
          onClick={() => handleClick(plan, isActive)} >
          { isActive ? 'ACTIVE' : 'Choose plan' }
        </Button>
      </Box>
    </Card> 
  );
};

export default Plan;


