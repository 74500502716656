import React from "react";
import { Link } from "react-router-dom";
import { Box, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import "./components.css";

function HeroSection() {
    return (
        <div className="background hero-container" style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '100vh',
            position: 'relative'
        }}>
            <div className="content-overlay" style={{
                textAlign: 'center',
                padding: '20px 0',
                flexGrow: 1,
            }}>
                <h1 className="gradient-text" style={{ fontWeight: 700, margin: '0.5em 0' }}>Go faster with karting specialised artificial intelligence</h1>
                <p className="responsive-h4" style={{ fontWeight: "300", fontSize: "24px", margin: '0.5em 0', color: "white" }}>Get personalised technical and practical suggestions to improve your performances on track</p>
                <div className="hero-btns" style={{ display: 'flex', justifyContent: 'center', gap: '1em' }}>
                    {localStorage.getItem("access_token") ? (
                        <Link to="/sign-up" className="insertdata">
                            <Button variant="contained" color="light" onClick={() => console.log("hey")}>
                                Try Free
                            </Button>
                        </Link>
                    ) : (
                        <Link to="/sign-up" className="insertdata">
                            <Button variant="contained" color="light" onClick={() => console.log("hey")}>
                              Try Free 
                            </Button>
                        </Link>
                    )}
                    <ScrollLink to="second-part-home" smooth={true} duration={500}>
                        <Button variant="outlined" color="light">
                            Learn More
                        </Button>
                    </ScrollLink>
                </div>
            </div>
			
            <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                width: '100%',
                bgcolor: 'transparent',
                p: 2,
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Box component="img" src={`${process.env.PUBLIC_URL}/images/kart-republic-logo-white.png`} alt="Logo" sx={{ width: 100, mb: 15 }} />
            </Box>
        </div>
    );
}

export default HeroSection;
