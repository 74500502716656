import 'swiper/css';

import { Box,Typography, Fade, Grid, ToggleButton, ToggleButtonGroup, styled } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCallback, useEffect, useState } from 'react';

import PayPalButton from 'components/PayPalButton';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Plan from 'components/Plans/Plan'
import PlanExternal from 'components/Plans/PlanExternal'
import myUserStore from 'stores/UserStore';
import { observer } from 'mobx-react-lite';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: '#fff',
  padding: '7px 24px',
  borderColor: 'transparent',
  '&.Mui-selected, &.Mui-selected:hover': {
    backgroundColor: '#fff',
    color: '#000',
  },
}))

const sliderOptions = {
  slidesPerView: 3,
  spaceBetween: 16,
  breakpoints: {
    // when window width is >= 320px
    320: {
      centeredSlides: true,
      slidesPerView: 1.1,
      spaceBetween: 16
    },
    // mui md screen >= 
    900: {
      centeredSlides: false,
      slidesPerView: 3,
      spaceBetween: 24
    }
  }
}

const pricingOptions1 = [
  {
    chip: 'Basic',
    chip: {
      color: 'primary',
      label: 'Basic',
      variant: 'outlined'
    },
    title: 'Growth',
    currency: '$',
    description: 'For drivers racing individually  looking to  use AiMotor to improve their performance ',
    price: '$25/month',
    yearPrice: '$280/year',
    priceNumber: 25,
    yearPriceNumber: 280,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Join Unlimited Teams',
      'You can cancel at any time',
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    chip: {
      color: 'secondary',
      label: 'most popular',
      variant: 'contained'
    },
    title: 'Pro',
    currency: '$',
    price: '$55/month',
    description: 'For drivers seeking to use AiMotor to compare and improve their performance within a single, exclusive group of drivers and with our best AI model ',
    yearPrice: '$640/year',
    priceNumber: 55,
    yearPriceNumber: 640,
    features: [
 'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Join Unlimited Teams',
      'You can cancel at any time',
      'AI model AiMotor 2',
      'Create One Team (up to 5 members)',
      'Multi Data Insertion',
      'Team Archive',
      'Team Analytics',
      'Team Management Dashboard',
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    chip: {
      color: 'success',
      label: 'best',
      variant: 'contained'
    },
    title: 'Team',
    price: '$70/month',
    description: 'For drivers seeking to use AiMotor to compare and improve their performance with multiple and large groups of drivers and with our best AI model',
    yearPrice: '$820/year',
    currency: '$',
    priceNumber: 70,
    yearPriceNumber: 820,
    features: [
      'Personal Space',
      'AI model AiMotor 1',
      'Data Insertion',
      'Data Archive',
      'Setup Analytics',
      'Join Unlimited Teams',
      'You can cancel at any time',
      'AI model AiMotor 2',
      'Multi Data Insertion',
      'Team Archive',
      'Team Analytics',

      'Team Management Dashboard',
      'Create Unlimited teams',
      'Invite Unlimited team members',
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
];

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "EUR",
  intent: "subscription",
  vault : true,
};


/**
 * 
 * @param {*} param0 
 * @param {*} onSuccess  se il piano e andato in success comunicare al parent il cambio
 * @returns 
 */
function Plans({ isExternal = false, onPlanClick = () => {}, onSuccess = () => {}, onUndo = () => {}, showAll}) {
  const [loading, setLoading] = useState(false) // show loader on modal open
  const [plainId, setPlanId] = useState();
  const [period, setPeriod] = useState("MONTH");
  const [showPaypal, setShowPaypal] = useState(false)
  const [newTmpPlan, setNewTmpPlan] = useState({})
  const [selectedPrice, setSelectedPrice] = useState(0)
  const [selectedYearPrice, setSelectedYearPrice] = useState(0)
  const [pricingOptions, setPricingOptions] = useState([]);

  const myPlan = !showAll || myUserStore?.subscriptionDetails == undefined ?  myUserStore?.subscriptionDetails?.custom_id  :  "Growth"
  const isTrial = !myPlan
  console.log ('isTeam',myUserStore?.subscriptionDetails, isTrial )
  console.log(myPlan)

  useEffect(() => {
    let filteredOptions;
    if (myPlan) {
      filteredOptions = pricingOptions1.filter(option => {
        if (myPlan === 'Growth') return true;
        if (myPlan === 'Pro') return option.title === 'Pro' || option.title === 'Team';
        if (myPlan === 'Team') return option.title === 'Team';
        return false;
      });
    } else {
      filteredOptions = pricingOptions1;
    }
    setPricingOptions(filteredOptions);
  }, [myPlan, pricingOptions1]);

  

  const selectedPlanId = myUserStore.subscriptionDetails?.custom_id
  const subscriptionId = myUserStore.subscriptionId

  const onUndoPayment = () => {
    setShowPaypal(false)
    setNewTmpPlan(undefined)
    // notify parent
    onUndo()
  }


  const onPaymentComplete = (subscriptionDetails) => {
    myUserStore.setUserSubscriptionDetails(subscriptionDetails)
    setNewTmpPlan(undefined)
    setShowPaypal(false)
    // notify parent
    onSuccess()
  }

  const handlePlanClick = (plan, isActive) => {
    // if active -> prevent user click
    if (!isActive) {
      // prepare button to pay
      setShowPaypal(true)
      setSelectedPrice(plan.priceNumber);
      setSelectedYearPrice(plan.yearPriceNumber)
      setNewTmpPlan(plan)
      onPlanClick(plan)
    }
  }

  const handleSwitchChange = (value) => {
    setPeriod(value);
  };

  const PlanByType = useCallback((props) => {
    if (isExternal) {
      return <PlanExternal {...props} />
    } else {
      return <Plan {...props} />
    }
  }, [isExternal])

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center" mb={3}>
        <ToggleButtonGroup
          color="light"
          size="small"
          value={period}
          exclusive
          onChange={(e, value) => handleSwitchChange(value)}
          aria-label="Platform"
          sx={{
            overflow: 'hidden',
            borderRadius: '30px',
            border: '1px solid #fff'
          }}
        >
          <StyledToggleButton value="MONTH">MONTHLY</StyledToggleButton>
          <StyledToggleButton value="YEAR">YEARLY</StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box as={Swiper} {...sliderOptions} sx={{ px: { md: 3 } }}>
        {pricingOptions.map((option, index) => {
          const hasPlan = newTmpPlan && newTmpPlan?.title;
          const isSelected = newTmpPlan?.title && newTmpPlan.title === option.title;
          return (
            <SwiperSlide key={index} style={{ height: 'auto' }}>
              <PlanByType
                plan={option}
                period={period}
                isActive={selectedPlanId === option.title}
                loading={loading}
                onClick={handlePlanClick}
                sx={{
                  opacity: hasPlan ? (isSelected ? 1 : 0.2) : 1,
                  disabled: hasPlan && !isSelected
                }}
              />
            </SwiperSlide>
          );
        })}
      </Box>
      <Grid container spacing={2} justifyContent="center" alignItems="stretch"></Grid>
      <Fade in={showPaypal} unmountOnExit>
        <Box px={3}>
          <PayPalScriptProvider options={initialOptions}>
            <PayPalButton
              period={period}
              price={period === 'MONTH' ? selectedPrice : selectedYearPrice}
              planId={plainId}
              currentSubscriptionId={subscriptionId}
              selectedPlan={newTmpPlan}
              onPaymentComplete={onPaymentComplete}
              onUndoPayment={onUndoPayment}
              isTrial={isTrial}
            />
          </PayPalScriptProvider>
        </Box>
      </Fade>
    </Box>
  );
};

export default observer(Plans);