import "./components/components.css";

import React, { useEffect, useState } from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";

import ActivateAccount from "components/pages/User/ActivateAccount";
import ActivateAccountDetail from "components/pages/User/ActivateAccountDetail";
import AnalyzeDataComponent from "./components/AnalyzeDataComponent";
import Clients from "./components/Clients";
import Contactus from "./components/pages/Contactus";
import FeedbackPopup from "./components/FeedbackPopup"; // Import the FeedbackPopup component
import Floatingbutton from "./components/Floatingbutton";
import ForgotPassword from "./components/pages/ForgotPassword";
import FutureUpdates from "./components/FutureUpdates";
import GetStarted from "./components/pages/GetStarted";
import HeroSectionExchangeData from "./components/HeroSectionExchangeData";
import Home from "./components/pages/Home";
import InsertData from "./components/pages/InsertData";
import InsertForm from "./components/InsertForm";
import InvitedMember from "./components/pages/InvitedMember";
import LearnForm from "./components/LearnForm";
import Navbar from "./components/Navbar";
import ParentRemoteGarage from "./components/pages/ParentRemoteGarage";
import ParentRemoteGarageDriver from "./components/pages/ParentRemoteGarageDriver";
import PayPalButton from "./components/PayPalButton";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PricingFixed from "./components/pages/PricingFixed";
import PricingSignUp from "./components/pages/PricingSignUp";
import RaceWeekend from "./components/pages/RaceWeekend";
import RaceWeekendDriver from "./components/pages/RaceWeekendDriver";
import RacingSpace from "./components/pages/RacingSpace";
import RemoteControl from "./components/pages/RemoteContorl";
import ResetPassword from "./components/pages/ResetPassword";
import SecondPartHome from "./components/SecondPartHome";
import Settings1 from "./components/pages/Settings1";
import SignIn from "./components/pages/SignIn";
import SignUp from "./components/pages/SignUp";
import SignUpForm from "./components/SignUpForm";
import Support from "./components/Support";
import TermsOfService from "./components/pages/TermsOfService";
import ThemeWrapper from "components/Layout/ThemeProvider";
import { ToastContainer } from "react-toastify";
import ViewData from "./components/pages/ViewData";
import aiProduct from "./components/pages/aiProduct";
import analyticsProduct from "./components/pages/analyticsProduct";
import archiveProduct from "./components/pages/archiveProduct";
import callToAction from "./components/pages/CallToAction";
import insertProduct from "./components/pages/insertProduct";
import Privacy from "./components/pages/Privacy";
import ChatBot from "./components/pages/ChatBot";
import { jwtDecode } from "jwt-decode";
import myUserStore from "./stores/UserStore";
import { observer } from "mobx-react-lite";
import teamEnvironmentProduct from "./components/pages/teamEnvironmentProduct";
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';
import Infinitescroll from "./components/Infinitescroll";

function App() {
	const [isPopupOpen, setPopupOpen] = useState(false);
	// is true by default
	const [isLoggedIn, setIsLoggedIn] = useState(true);

	// A wrapper for <Route> that redirects to the login
	// screen if you're not yet authenticated.
	function PrivateRoute({ children, redirectTo = "/sign-up", ...rest }) {
		return (
			<Route
				{...rest}
				render={({ location }) =>
					isLoggedIn ? (
						children
					) : (
						<Redirect
							to={{
								pathname: redirectTo,
								state: { from: location }
							}}
						/>
					)
				}
			/>
		);
	}

	useEffect(() => {
		setIsLoggedIn(myUserStore.isLoggedIn)
	}, [myUserStore.isLoggedIn])

	const openPopup = () => {
		setPopupOpen(true);
	};

	const closePopup = () => {
		setPopupOpen(false);
	};

	useEffect(() => {
		// Open the popup by default when the component mounts
		setPopupOpen(true);
		document.title = "AiMotor";
	}, []);

	const checkTokenValidity = () => {
		const token = localStorage.getItem("access_token");

		if (token) {
			try {
				const decodedToken = jwtDecode(token);

				// Check if the token is expired
				const currentTimestamp = Math.floor(Date.now() / 1000);
				if (decodedToken.exp < currentTimestamp) {
					// Token is expired, handle accordingly (e.g., refresh token or redirect to login)
					localStorage.removeItem("access_token");
					myUserStore.setIsLoggedIn(false);
					localStorage.removeItem("user_data");
				} else {
					// Token is valid
					console.log("Token is still valid");
				}
			} catch (error) {
				// Error decoding token (e.g., invalid token format)
				localStorage.removeItem("access_token");
				localStorage.removeItem("user_data");
				myUserStore.setIsLoggedIn(false);
			}
		} else {
			// Token not present, user is not logged in
			localStorage.removeItem("access_token");
			localStorage.removeItem("user_data");
			myUserStore.setIsLoggedIn(false);
			// window.location.reload();
		}
	};
	useEffect(() => {
		// Initial check when the component mounts
		checkTokenValidity();

		const handleVisibilityChange = () => {
			if (!document.hidden) {
				// Page is visible, trigger token check
				checkTokenValidity();
			}
		};

		// Listen for visibility change events
		document.addEventListener("visibilitychange", handleVisibilityChange);

		// Clean up the event listener when the component unmounts
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	return (
		<ThemeWrapper>
			<title>AiMotor</title>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={false}
			/>
			<Router>
				<Switch>
					<PrivateRoute path="/racing-space">
						<RacingSpace isLoggedIn={isLoggedIn} />
					</PrivateRoute>
					<Route path="/invited-member">

						<InvitedMember />
					</Route>
					<Route path="/get-started">

						<GetStarted />
					</Route>

					<Route path="/">
						<Navbar isLoggedIn={isLoggedIn} />
						<Route path="/" exact component={Home} />
						<Route path="/chat-bot" component={ChatBot} />
						<Route path="/reset_password" component={ResetPassword} />
						<Route path="/second-part-home" component={SecondPartHome} />
						<Route path="/activate_account" component={ActivateAccount} />
						<Route path="/activate_account_detail" component={ActivateAccountDetail} />
						<Route path="/privacy" component={Privacy} />
						{/* <Route path="/get-started" component={GetStarted} /> */}
						<Route path="/insert-data" component={InsertData} />
						<Route path="/view-data" component={ViewData} />
						<Route path="/sign-in" component={SignIn} />
						<Route path="/control-data" component={HeroSectionExchangeData} />
						<Route path="/sign-up" component={SignUp} />
						<Route path="/learn-more" component={LearnForm} />
						<Route path="/recover-password" component={ForgotPassword} />
						<Route path="/future-updates-page" component={FutureUpdates} />
						<Route path="/analyze-data" component={AnalyzeDataComponent} />
						<Route path="/pricing-fixed" component={PricingFixed} />
						<Route path="/analytics-product" component={analyticsProduct} />
						<Route path="/call-to-action" component={callToAction} />
						<Route path="/team-environment-product" component={teamEnvironmentProduct} />
						<Route path="/archive-product" component={archiveProduct} />
						{/* <Route path="/FeedbackPopup" component={FeedbackPopup} /> */}
						<Route path="/clients" component={Clients} />
						<Route path="/pricing-sign-up" component={PricingSignUp} />
						<Route path="/ai-product" component={aiProduct} />
						<Route path="/support" component={Support} />
						<Route path="/infinite-scroll" component={Infinitescroll} />
						<Route path="/remote-control" component={RemoteControl} />
						<Route path="/race-weekend" component={RaceWeekend} />
						<Route path="/terms-of-service" component={TermsOfService} />
						<Route path="/parent-remote-garage" component={ParentRemoteGarage} />
						<Route path="/contact-us" component={Contactus} />
						<Route path="/insert-product" component={insertProduct} />
						<Route path="/parent-remote-garage-driver" component={ParentRemoteGarageDriver} />
						{/* <Route path="/insert-form" component={InsertForm} /> */}
					</Route>
				</Switch>
				{/* <Floatingbutton handleShow={openPopup} /> */}
			</Router>
		</ThemeWrapper>
	);
}

export default observer(App);
