import Menu, { MenuProps } from '@mui/material/Menu';
import React, { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ArchiveIcon from '@mui/icons-material/Archive';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Chip from '@mui/material/Chip';
import CorniceEngine from './CorniceEngine';
import CorniceFront from './CorniceFront';
import CornicePressure from './CornicePressure';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FormControl from '@mui/material/FormControl';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Mmenu from '@mui/material/Menu'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import OutlinedInput from '@mui/material/OutlinedInput'; // Corrected import path for OutlinedInput component
import { PieChart } from '@mui/x-charts/PieChart';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { useTheme } from '@mui/material/styles';

const itemHeight = 48;
const itemPaddingTop = 8;
const menuProps = {
  PaperProps: {
    style: {
      maxHeight: itemHeight * 4.5 + itemPaddingTop,
      width: 250,
    },
  },
};









function getStyles(name, personName, theme) { // Pass 'name' and 'personName' as arguments
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}




function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PieChartWithSelect = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = React.useState(0);
  const [anchorElTeamAnalysis, setAnchorElTeamAnalysis] = useState(null);
  const [selectedOptionTeamAnalysis, setSelectedOptionTeamAnalysis] = useState('');
  const [competitions, setCompetitions] = useState([]);
  const [racingData, setRacingData] = useState([]);

  const [showFront, setShowFront] = useState(false);

  const [showPressure, setShowPressure] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [emailName, setEmailName] = useState([]);
  const [driver, setDriver] = useState([]);
  const [showEngine, setShowEngine] = useState(true); // Use showEngine for engine graphs
  const [race, setRace] = useState("");
  const [day, setDay] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [competitionLoading, setCompetitionLoading] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setShowEngine(newValue === 0)
    setShowFront(newValue === 1)
    setShowPressure(newValue === 2)
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleMenuClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-calendar-popover' : undefined;











  //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
  const selectedTeam = myUserStore.getSelectedTeam;
  const showPersonalButton = myUserStore.isPersonalSpace;
  console.log("selected team", selectedTeam.id)
  

  useEffect(() => {
    getDrivers(false);
  }, [,selectedTeam]);

  const getDrivers = async (filter, racingData) => {
    axios
      .get(`https://aimotor.eu/api/get_drivers/?team_id=${selectedTeam.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        if (showPersonalButton) {
          setDrivers(res.data)
          setDriver([res.data[0].email])



        } else {
          setDrivers(res.data)
          setEmailName(res.data)
        }
        
        if (filter) {
          const racingDataEmails = racingData.map(item => item.email);
          const filteredDrivers = res.data.filter(driver => racingDataEmails.includes(driver.email));
          setDrivers(filteredDrivers);
          setEmailName(filteredDrivers)
          console.log("filtered drivers", filteredDrivers)

        }
        console.log("Drivers API Response:", drivers);

      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }


  console.log("Imported Race", race)
  console.log("Array email name", emailName)





  useEffect(() => {
    setCompetitionLoading(true);
    const fetchCompetitions = async () => {
      try {
        const response = await axios.get(`https://aimotor.eu/api/get_competitions/?team_id=${selectedTeam.id}`);
        const data = response.data;
        console.log("Competitions API Response:", data);
        setCompetitions(data); // Set competitions state with the data
        setCompetitionLoading(false);
      } catch (error) {
        setCompetitionLoading(false);
        console.error('Error fetching competitions:', error);
      }
    };

    fetchCompetitions(); // Call the fetchCompetitions function when the component mounts

  }, [, selectedTeam]);


  

  useEffect(() => {
    // Costruisci l'URL API con i parametri selezionati
    const apiUrl = `https://aimotor.eu/api/get_engine_graph_data?email=${driver}&race=${race}&day=${day}&team_id=${selectedTeam.id}`;
    console.log("API URL:", apiUrl);

    axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        accept: "application/json",
      },
    })
    .then((response) => {
      const data = response.data;
      console.log("API Response:", data); // Log the API response
      setRacingData(data);
      getDrivers(true, data);
    })
    .catch((error) => {
      console.error("Query Error:", error);
    });
  }, [ race, day, selectedTeam]);



  const handleChange = (event) => {
    // If multiple options can be selected, event.target.value will be an array
    const selectedDrivers = event.target.value;
    setDriver(selectedDrivers);
  };




  {/*const [race, setRace] = useState('');*/ }
  // const [day, setDay] = useState('');


  ;


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDay = (day) => {
    console.log('Selected day:', day);
    setDay(day);
    handleMenuClose();
  };

  const racingDays = [
    { name: 'First Racing Day', value: 1 },
    { name: 'Second Racing Day', value: 2 },
    { name: 'Third Racing Day', value: 3 },
    { name: 'Fourth Racing Day', value: 4 },
    { name: 'Fifth Racing Day', value: 5 },
    { name: 'Sixth Racing Day', value: 6 },
    { name: 'Seventh Racing Day', value: 7 },
  ];



  console.log("email name", emailName);

  return (

    <div>


      <Grid container columnSpacing={3} sx={{ alignItems: "center" }} >
        <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
          <Typography sx={{ fontSize: 40 }} > Setup analytics</Typography>
        </Grid>
        <Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>
          <div className="frame1" style={{ display: 'flex', flexDirection: "row", alignItems: 'center', gap: '10px', width: '100%' }}>

                <FormControl fullWidth sx={{ textAlign: 'left', background: 'rgba(0, 0, 0, 0.00)', minWidth: 100 }}>
      <InputLabel id="race-select-label">RACE</InputLabel>
      <Select
        labelId="race-select-label"
        fullWidth
        id="race-select"
        value={race}
        onChange={(e) => {setRace(e.target.value); 
           setDriver([]); }}
        label="Race"
      >
        {!competitionLoading ?
          (competitions.length === 0 ? 
            <MenuItem value="">Please Insert a Competition</MenuItem>
           :
            [
              <MenuItem key="select-race" value="">Select Race</MenuItem>,
              ...competitions.map((competition) => (
                <MenuItem key={competition.id} value={competition.id}>
                  {competition.competition} ({competition.track})
                </MenuItem>
              ))
            ]
          ) : (
            <MenuItem value="" disabled>
              <Skeleton variant="rectangular" sx={{ borderRadius: '8px', marginLeft: 0.5 }} height={30} />
            </MenuItem>
          )}
      </Select>

   
    </FormControl>
    <Button
        className="button"
        aria-controls="number-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        style={{ whiteSpace: 'nowrap', color: (!day ? 'red' : 'green') }}
      >
        <CalendarMonthIcon className="calendar-month" />
      </Button>
      <Mmenu
        id="number-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {racingDays.map((racingDay) => (
          <MenuItem key={racingDay.value} onClick={() => handleSelectDay(racingDay.value)}>
            {racingDay.name}
          </MenuItem>
        ))}
      </Mmenu>
          </div>

        </Grid>
        {!showPersonalButton && (<Grid item lg={showPersonalButton ? 6 : 4} md={showPersonalButton ? 6 : 4} sm={showPersonalButton ? 6 : 4} xs={12} sx={{ marginBlock: 2 }}>

          <div className="autocomplete-wrapper" style={{ width: "100%" }}>
            <div className="autocomplete" style={{ width: '100%' }}>
              <FormControl sx={{}} style={{ width: '100%' }}>
                <InputLabel id="demo-multiple-chip-label">Driver</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={driver}
                  label="driver "
                  onChange={handleChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        const selectedName = emailName.find(dataItem => dataItem.email === value);
                        const name = selectedName ? selectedName.surname : '';
                        return (
                          <Chip key={value} label={name} />
                        );
                      })}
                    </Box>
                  )}
                >
                 {drivers.length === 0 ? (
                    <MenuItem key="no-driver" value="">
                      No driver has inserted yet!
                    </MenuItem>
                  ) : (
                    drivers.map((driver) => (
                      <MenuItem key={driver.id} value={driver.email}>
                        {driver.surname}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
        </Grid>)}

      </Grid>



                 
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs variant="fullWidth" value={value} onChange={handleChangeTab} >
            <Tab label="ENGINE" {...a11yProps(0)} />
            <Tab label="CHASSIS" {...a11yProps(1)} />
            <Tab label="PRESSURE" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <div className='graphs'>
          <>
            {showEngine && <CorniceEngine race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day}  />}
            {showFront && <CorniceFront race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day} />}
            {showPressure && <CornicePressure race={race} day={day} driver={driver} fullName={emailName} showTable={!!race && !!day} />}
          </>

        </div>

      </Box>








    </div>
  );
};

export default observer(PieChartWithSelect);
