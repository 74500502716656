import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./components.css";
import { Container, Row, Col, Table, Modal, Button } from "react-bootstrap";
import axios from "axios";
import Form from "react-bootstrap/Form";
import dayjs from "dayjs";
import { IoMdCreate } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import EditableCell from "./EditableCell";
import EditableCellSelect from "./EditableCellSelect";
import EditableCellNumeric from "./EditableCellNumeric";
import { MdDelete } from "react-icons/md";
import Select from "react-select";

const RemoteForm = ({ selectedCompetition, selectedTrack })  => {




const calculateStatistics = (fieldName, filteredData) => {
  if (!filteredData || filteredData.length === 0) {
    return {
      average: 0,
      max: 0,
      min: 0
    };
  }

  const total = filteredData.reduce((acc, entry) => acc + parseFloat(entry[fieldName]), 0);
  const average = (total / filteredData.length).toFixed(2);

  // Calculate maximum and minimum values
  let maxValue = -Infinity;
  let minValue = Infinity;
  filteredData.forEach(entry => {
    const value = parseFloat(entry[fieldName]);
    if (value > maxValue) {
      maxValue = value;
    }
    if (value < minValue) {
      minValue = value;
    }
  });

  let averageLabel;
  if (fieldName === 'axle_type') {
    averageLabel = mapHardness(average);
  } else if (fieldName === 'axle_position') {
    averageLabel = mapHeight(average);
  } else {
    averageLabel = average;
  }

  return {
    average: averageLabel,
    max: maxValue.toFixed(2),
    min: minValue.toFixed(2)
  };
};





// Within the return statement where you're displaying statistics
<tbody>
  <tr>
    <td>Camber</td>
    {calculateStatistics('camber').average}
    <td>{calculateStatistics('camber').max}</td>
    <td>{calculateStatistics('camber').min}</td>
  </tr>
  <tr>
    <td>Caster</td>
    {calculateStatistics('caster').average}
    <td>{calculateStatistics('caster').max}</td>
    <td>{calculateStatistics('caster').min}</td>
  </tr>
  <tr>
    <td>Max Exhaust</td>
    {calculateStatistics('max_exhaust').average}
    <td>{calculateStatistics('max_exhaust').max}</td>
    <td>{calculateStatistics('max_exhaust').min}</td>
  </tr>
  <tr>
    <td>Min Exhaust</td>
    {calculateStatistics('min_exhaust').average}
    <td>{calculateStatistics('min_exhaust').max}</td>
    <td>{calculateStatistics('min_exhaust').min}</td>
  </tr>
  <tr>
    <td>Max RPM</td>
    {calculateStatistics('max_rpm').average}
    <td>{calculateStatistics('max_rpm').max}</td>
    <td>{calculateStatistics('max_rpm').min}</td>
  </tr>
  <tr>
    <td>Min RPM</td>
    {calculateStatistics('min_rpm').average}
    <td>{calculateStatistics('min_rpm').max}</td>
    <td>{calculateStatistics('min_rpm').min}</td>
  </tr>
</tbody>


  const mapperYN = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];
  const history = useHistory();
  const [racingData, setRacingData] = useState([]);
  const [driver, setDriver] = useState("All");
  const [drivers, setDrivers] = useState(["All"]);
  const [track, setTrack] = useState("All");
  const [tracks, setTracks] = useState(["All"]);
  const [sessionType, setSessionType] = useState("All");
  const [sessionTypes, setSessionTypes] = useState(["All"]);
  const [category, setCategory] = useState("All");
  const [sessionNumber, setSessionNumber] = useState("All");
  const [sessionGroup, setSessionGroup] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [id, setId] = useState(-1);
  const [show, setShow] = useState(false);
  const [race, setRace] = useState("");


  const token = localStorage.getItem("access_token");

  useEffect(() => {
    // Check if the user is logged in (e.g., by checking the presence of the access token in local storage
    const params = JSON.parse(localStorage.getItem("user_data"));
    // const apiUrl = `https://aimotor.eu/api/get_race_remote_data/`;
    const apiUrl = `https://aimotor.eu/api/get_total_race_data/`;

    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data !== null) {
          setRacingData(data);
        }
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  }, [show]);

  useEffect(() => {
    fetchRacingData();
  }, [selectedCompetition, selectedTrack]);

  const fetchRacingData = () => {
    axios
      .get(`https://aimotor.eu/api/get_total_race_data/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data !== null) {
          setRacingData(data);
        }
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  };

  useEffect(() => {
    filterData();
  }, [racingData, selectedCompetition, selectedTrack]);
  const filterData = () => {
    if (racingData) {
      let filteredSessions = racingData.filter((session) => {
        return (
          session.race === selectedCompetition &&
          session.track === selectedTrack &&
          session.category === category &&
          sessionGroup.includes(session.session_group) // Filter by session group
        );
      });
  
      setFilteredData(filteredSessions);
    }
  };
  


  useEffect(() => {
    setTracks(["All", ...new Set(racingData.map((data) => data.track))]);
    setSessionTypes([
      "All",
      ...new Set(racingData.map((data) => data.session_type)),
    ]);
    setDrivers(["All", ...new Set(racingData.map((data) => data.full_name))]);
  }, [racingData]);

  useEffect(() => {
    filterData();
  }, [racingData, driver, track, sessionType, category, sessionNumber, sessionGroup]);


  const mapHeight = (value) => {
    switch (value) {
      case 0:
        return "Low";
      case 1:
        return "Medium";
      case 2:
        return "High";
      default:
        return "Unknown";
    }
  };

  const mapTiming = (value) => {
    switch (parseInt(value)) {
      case 0:
        return "Too early";
      case 1:
        return "Normal";
      case 2:
        return "Too late";
      default:
        return "Unknown";
    }
  };

  const mapHardness = (value) => {
    switch (parseInt(value)) {
      case 0:
        return "Soft";
      case 1:
        return "Medium";
      case 2:
        return "Hard";
      case 3:
        return "Rain";
      default:
        return "Unknown";
    }
  };

  const mapCarburetor= (value) => {
		switch (parseInt(value)) {
			case 1:
				return "Tilltson (Junior)";
			case 2:
				return "Ibea F3";
			case 3:
				return "Ibea F6";
      case 4:
        return "TM";
      case 5:
        return "Tilltson New (Senior)";
      case 6:
        return "Tilltson Old (Senior)";

      default:
				return "Unknown";
		} 
	};
  

  const updateValue = (value, field, id) => {
    // query to update
    axios
      .post(
        `https://aimotor.eu/api/change_data/?value=${value}&field=${field}&id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "access_token"
            )}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        const userData = userResponse.data;
      })
      .catch((error) => {
        toast.error("Failed to update the value!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const deleteValue = (id) => {
    // query to update
    axios
      .post(
        `https://aimotor.eu/api/delete_data/?id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "access_token"
            )}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        toast.success("Entry deleted succesfully!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        handleClose();
        // window.location.reload();
      })
      .catch((error) => {
        toast.error("Failed to delete the entry!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const sessionGroupOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "A" },
    { value: "6", label: "B" },
    { value: "7", label: "C" },
    { value: "8", label: "D" },
    { value: "9", label: "E" },
    { value: "10", label: "F" },
    { value: "11", label: "G" },
    { value: "12", label: "H" }
  ];

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
       // Set the background color of the options list to black
      zIndex: 9999, // Set a high z-index value to ensure it appears above other elements
    }),
  };

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => deleteValue(id)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Form>
        <Row>
    
          <Col md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="categorySelect">Select Category: </Form.Label>
              <Form.Select
                id="categorySelect"
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              >
                <option value="">Category</option>
                <option value="MINI">Mini</option>
                <option value="JUNIOR">Junior</option>
                <option value="SENIOR">Senior</option>
                <option value="SHIFTER">Shifter</option>
              </Form.Select>
            </Form.Group>
          </Col>
         
          <Col md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="sessionNumberSelect">Select Session Number: </Form.Label>
              <Form.Select
                id="sessionNumberSelect"
                value={sessionNumber}
                onChange={(event) => setSessionNumber(event.target.value)}
              >
                <option value="">Session Number</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Form.Select>
            </Form.Group>
          </Col>
         <Col md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="sessionGroupSelect">Select Session Group: </Form.Label>
              <Select
                id="sessionGroupSelect"
                options={sessionGroupOptions}
                isMulti
                value={sessionGroupOptions.filter(option => sessionGroup.includes(option.value))}
                onChange={(selectedOptions) => setSessionGroup(selectedOptions.map(option => option.value))}
                styles={customStyles} // Apply custom styles to the Select component
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {filteredData.length > 0 ? (
        <Table responsive striped bordered hover className=" custom-table">
           <thead>
            <tr>
              <th></th>
              <th>Date</th>
              <th>Driver</th>
              <th>Lap Time</th>
              <th>Chassis Number</th>
              <th>Engine Number</th>
              <th>Tires New</th>
              <th>Carburetor</th>
              <th>Hot Pressure</th>
              <th>Cold Pressure</th>
              <th>Max Exhaust</th>
              <th>Min Exhaust</th>
              <th>Sprocket</th>
              <th>Pinion</th>
              <th>Asphalt Temperature</th>
              <th>Max Speed</th>
              <th>Max RPM</th>
              <th>Min RPM</th>
              <th>Caster</th>
              <th>Camber</th>
              <th>Axle Type</th>
              <th>Axle Position</th>
              </tr> 
          </thead>
          <tbody>
            {filteredData.map((entry, index) => (
              <tr key={index}>
                <td style={{ verticalAlign: "middle" }}>
                  <MdDelete
                    size="1.5rem"
                    onClick={() => handleShow(entry.id)}
                  />
                </td>
                <EditableCell
                  value={dayjs(entry.timestamp_location).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                  onSave={updateValue}
                  field="timestamp"
                  id={entry.id}
                />
                <td>{entry.full_name}</td>
                <EditableCell
                  value={entry.laptime}
                  onSave={updateValue}
                  field="laptime"
                  id={entry.id}
                />
                <EditableCell
                  value={entry.chassis_number}
                  onSave={updateValue}
                  field="chassis_number"
                  id={entry.id}
                />
                <EditableCell
                  value={entry.engine_number}
                  onSave={updateValue}
                  field="engine_number"
                  id={entry.id}
                />
                <EditableCellSelect
                  value={entry.tires_new}
                  onSave={updateValue}
                  field="tires_new"
                  id={entry.id}
                  vals={mapperYN}
                />
                <td>{mapCarburetor(entry.carburetor)}</td>
                <EditableCellNumeric
                  value={entry.pressure_hot}
                  onSave={updateValue}
                  field="pressure_hot"
                  id={entry.id}
                  min="0"
                  max="500"
                />
                <EditableCellNumeric
                  value={entry.pressure_cold}
                  onSave={updateValue}
                  field="pressure_cold"
                  id={entry.id}
                  min="0"
                  max="500"
                />
                <td>{entry.max_exhaust}</td>
                <td>{entry.min_exhaust}</td>
                <EditableCellNumeric
                  value={entry.crown}
                  onSave={updateValue}
                  field="crown"
                  id={entry.id}
                  min="0"
                  max="150"
                />
                 <EditableCellNumeric
                  value={entry.pinion}
                  onSave={updateValue}
                  field="pinion"
                  id={entry.id}
                  min="0"
                  max="20"
                />
                <EditableCellNumeric
                  value={entry.temperature}
                  onSave={updateValue}
                  field="temperature"
                  id={entry.id}
                  min="-60"
                  max="60"
                />
                <EditableCellNumeric
                  value={entry.max_speed}
                  onSave={updateValue}
                  field="max_speed"
                  id={entry.id}
                  min="0"
                  max="200"
                />
                <td>{entry.max_rpm}</td>
                <td>{entry.min_rpm}</td>
        
                <EditableCellNumeric
                  value={entry.caster}
                  onSave={updateValue}
                  field="caster"
                  id={entry.id}
                  min="-60"
                  max="60"
                />
                <EditableCellNumeric
                  value={entry.camber}
                  onSave={updateValue}
                  field="camber"
                  id={entry.id}
                  min="-60"
                  max="60"
                />
                <td>{entry.axle_type}</td>
                <td>{mapHeight(entry.axle_position)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>Select a Category, a Session Number and a Session Group.</p>
      )}

<Container>
  <Row>
    {/* <Col xs={12} sm={6} md={4}>
      <h1>Statistics</h1>
      <Table bordered>
        <thead>
          <tr>
            <th className="header">FIELD</th>
            <th className="header">AVERAGE</th>
            <th className="header">MAXIMUM</th>
            <th className="header">MINIMUM</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Camber</td>
            <td>{isNaN(calculateStatistics('camber',filteredData).average) ? 0 : calculateStatistics('camber',filteredData).average}</td>
            <td>{calculateStatistics('camber',filteredData).max}</td>
            <td>{calculateStatistics('camber',filteredData).min}</td>
          </tr>
          <tr>
            <td>Caster</td>
            <td>{isNaN(calculateStatistics('caster',filteredData).average) ? 0 : calculateStatistics('caster',filteredData).average}</td>
            <td>{calculateStatistics('caster',filteredData).max}</td>
            <td>{calculateStatistics('caster',filteredData).min}</td>
          </tr>
          <tr>
            <td>Max Exhaust</td>
            <td>{isNaN(calculateStatistics('max_exhaust',filteredData).average) ? 0 : calculateStatistics('max_exhaust',filteredData).average}</td>
            <td>{calculateStatistics('max_exhaust',filteredData).max}</td>
            <td>{calculateStatistics('max_exhaust',filteredData).min}</td>
          </tr>
          <tr>
            <td>Min Exhaust</td>
            <td>{isNaN(calculateStatistics('min_exhaust',filteredData).average) ? 0 : calculateStatistics('min_exhaust',filteredData).average}</td>
            <td>{calculateStatistics('min_exhaust',filteredData).max}</td>
            <td>{calculateStatistics('min_exhaust',filteredData).min}</td>
          </tr>
          <tr>
            <td>Max RPM</td>
            <td>{isNaN(calculateStatistics('max_rpm',filteredData).average) ? 0 : calculateStatistics('max_rpm',filteredData).average}</td>
            <td>{calculateStatistics('max_rpm',filteredData).max}</td>
            <td>{calculateStatistics('max_rpm',filteredData).min}</td>
          </tr>
          <tr>
            <td>Min RPM</td>
            <td>{isNaN(calculateStatistics('min_rpm',filteredData).average) ? 0 : calculateStatistics('min_rpm',filteredData).average}</td>
            <td>{calculateStatistics('min_rpm',filteredData).max}</td>
            <td>{calculateStatistics('min_rpm',filteredData).min}</td>
          </tr>
          <tr>
            <td>Hot Pressure</td>
            <td>{isNaN(calculateStatistics('pressure_hot',filteredData).average) ? 0 : calculateStatistics('pressure_hot',filteredData).average}</td>
            <td>{calculateStatistics('pressure_hot',filteredData).max}</td>
            <td>{calculateStatistics('pressure_hot',filteredData).min}</td>
          </tr>
          <tr>
            <td>Cold Pressure</td>
            <td>{isNaN(calculateStatistics('pressure_cold',filteredData).average) ? 0 : calculateStatistics('pressure_cold',filteredData).average}</td>
            <td>{calculateStatistics('pressure_cold',filteredData).max}</td>
            <td>{calculateStatistics('pressure_cold',filteredData).min}</td>
          </tr>
        </tbody>
      </Table>
    </Col> */}
  </Row>
</Container>

</Container>
  );
};

export default RemoteForm;