import React, { useState, useEffect } from "react";
import { Table, Container, DropdownButton, Dropdown, Form, Col,Button, Row } from "react-bootstrap";
import dayjs from "dayjs";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import EditableCell from "./EditableCell";
import EditableCellNumeric from "./EditableCellNumeric";
import EditableCellSelect from "./EditableCellSelect";
import { toast } from "react-toastify";

const SessionsByDateAndGroup = ({ raceWeekend }) => {
  const [sessionData, setSessionData] = useState([]);
  const [id, setId] = useState(-1);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState("");
  const [expandedTableIndex, setExpandedTableIndex] = useState(-1);
  const [day, setDay] = useState("All");
  
const handleToggleFullTable = (index) => {
  setExpandedTableIndex((prevIndex) => (prevIndex === index ? -1 : index));
};

  useEffect(() => {
    fetchSessionData();
  }, []);

  const fetchSessionData = async () => {
    try {
      const currentDate = dayjs().format("YYYY-MM-DD");
      const response = await axios.get(
        // `https://aimotor.eu/api/get_race_remote_data/?date=${currentDate}`,
        // `https://aimotor.eu/api/get_race_weekend_data/?`,
        `https://aimotor.eu/api/get_total_race_data/?`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );
      setSessionData(response.data);
   

      const initialShowFullTable = response.data.reduce((acc, session, index) => {
        acc[index] = false;
        return acc;
      }, {});
      setExpandedTableIndex(initialShowFullTable);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  
  };
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    // Update the corresponding filter state based on the name attribute of the select element
    if (name === "category") {
        setCategory(value);
    } else if (name === "day") {
        setDay(value);
    }
}

const filterSessions = () => {

  return sessionData.filter((session) => {
    return (
      (category === "" || session.category === category) &&
      (day === "" || session.day === parseInt(day)) &&
      (raceWeekend === "" || session.race_weekend === raceWeekend)
    );
  });
};








  

const renderTables = () => {

  const uniqueCombinations = getUniqueCombinations().sort((a, b) => a.sessionNumber - b.sessionNumber);

  const filteredCombinations = uniqueCombinations.filter(combination =>
    combination.sessions.some(session =>
      session.day === parseInt(day) &&
      session.category === category &&
      (raceWeekend === "All" || session.race_weekend === raceWeekend)
    )
  );

  return filteredCombinations.map((combination, index) => {
    const hasSelectedCriteria = combination.sessions.some(session =>
      session.day === parseInt(day) &&
      session.category === category &&
      (raceWeekend === "All" || session.race_weekend === raceWeekend)
    );

    if (!hasSelectedCriteria) {
      return null;
    }
    return (
      <Container key={index}>
        <div>
          <h2>Session Number {combination.sessionNumber} and Group {mapGroup(combination.sessionGroup)}</h2>
          <Button onClick={() => handleToggleFullTable(index)}>
            {expandedTableIndex === index ? "Show Less" : "Show More"}
          </Button>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Driver</th>
                <th>Lap Time</th>
                <th>Chassis Number</th>
                <th>Engine Number</th>
                <th>Tires New</th>
                <th>Carburetor</th>
                <th>Hot Pressure</th>
                <th>Cold Pressure</th>
                <th>Max Exhaust</th>
                <th>Min Exhaust</th>
                <th>Sprocket</th>
                <th>Pinion</th>
                <th>Asphalt Temperature</th>
                <th>Max Speed</th>
                <th>Max RPM</th>
                <th>Min RPM</th>
                <th>Caster</th>
                <th>Camber</th>
                <th>Axle Type</th>
                <th>Axle Position</th>
              </tr>
            </thead>
            <tbody>
              {sessionData
                .filter(session =>
                  session.session_number === combination.sessionNumber &&
                  session.session_group === combination.sessionGroup &&
                  session.category === category &&
                  ((raceWeekend === "All" && session.race_weekend !== null) || session.race_weekend === raceWeekend) &&
                  (day === "All" || (session.day !== null && parseInt(day) === session.day))
                )
                .slice(0, expandedTableIndex === index ? undefined : 1)
                .map((session, idx) => (
                  <tr key={idx}>
                    
                    <td>{session.full_name}</td>
                    <EditableCell
                      value={session.laptime}
                      onSave={updateValue}
                      field="laptime"
                      id={session.id}
                    />
                    <EditableCell
                      value={session.chassis_number}
                      onSave={updateValue}
                      field="chassis_number"
                      id={session.id}
                    />
                    <EditableCell
                      value={session.engine_number}
                      onSave={updateValue}
                      field="engine_number"
                      id={session.id}
                    />
                    <EditableCellSelect
                      value={session.tires_new}
                      onSave={updateValue}
                      field="tires_new"
                      id={session.id}
                      vals={mapperYN}
                    />
                    <td>{mapCarburetor(session.carburetor)}</td>
                    <EditableCellNumeric
                      value={session.pressure_hot}
                      onSave={updateValue}
                      field="pressure_hot"
                      id={session.id}
                      min="0"
                      max="500"
                    />
                    <EditableCellNumeric
                      value={session.pressure_cold}
                      onSave={updateValue}
                      field="pressure_cold"
                      id={session.id}
                      min="0"
                      max="500"
                    />
                    <td>{session.max_exhaust}</td>
                    <td>{session.min_exhaust}</td>
                    <EditableCellNumeric
                      value={session.crown}
                      onSave={updateValue}
                      field="crown"
                      id={session.id}
                      min="0"
                      max="100"
                    />
                    <EditableCellNumeric
                      value={session.pinion}
                      onSave={updateValue}
                      field="pinion"
                      id={session.id}
                      min="0"
                      max="20"
                    />
                    <EditableCellNumeric
                      value={session.temperature}
                      onSave={updateValue}
                      field="temperature"
                      id={session.id}
                      min="-60"
                      max="60"
                    />
                    <EditableCellNumeric
                      value={session.max_speed}
                      onSave={updateValue}
                      field="max_speed"
                      id={session.id}
                      min="0"
                      max="200"
                    />
                    <td>{session.max_rpm}</td>
                    <td>{session.min_rpm}</td>
                    <EditableCellNumeric
                      value={session.caster}
                      onSave={updateValue}
                      field="caster"
                      id={session.id}
                      min="-60"
                      max="60"
                    />
                    <EditableCellNumeric
                      value={session.camber}
                      onSave={updateValue}
                      field="camber"
                      id={session.id}
                      min="-60"
                      max="60"
                    />
                    <td>{session.axle_type}</td>
                    <td>{mapHeight(session.axle_position)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </Container>
    );
  });
};


  const getUniqueCombinations = () => {
    const uniqueCombinations = [];
    sessionData.forEach(session => {
      const combination = {
        sessionNumber: session.session_number,
        sessionGroup: session.session_group
      };
  
      // Check if the session has a non-null "day" attribute
      if (session.day !== null) {
        // Check if the combination already exists in uniqueCombinations
        const existingCombinationIndex = uniqueCombinations.findIndex(combo => 
          combo.sessionNumber === combination.sessionNumber && combo.sessionGroup === combination.sessionGroup
        );
  
        if (existingCombinationIndex !== -1) {
          // If combination exists, check if the session's day matches the other sessions in the combination
          if (uniqueCombinations[existingCombinationIndex].sessions.every(s => s.day === session.day)) {
            // If all sessions in the combination have the same day, push the session to the existing combination
            uniqueCombinations[existingCombinationIndex].sessions.push(session);
          }
        } else {
          // If combination does not exist, create a new combination
          uniqueCombinations.push({
            sessionNumber: combination.sessionNumber,
            sessionGroup: combination.sessionGroup,
            sessions: [session]
          });
        }
      }
    });
    return uniqueCombinations;
  };

const updateValue = (value, field, id) => {
    // query to update
    axios
      .post(
        `https://aimotor.eu/api/change_data/?value=${value}&field=${field}&id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "access_token"
            )}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        const userData = userResponse.data;
      })
      .catch((error) => {
        toast.error("Failed to update the value!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const mapHeight = (value) => {
    switch (value) {
      case 0:
        return "Low";
      case 1:
        return "Medium";
      case 2:
        return "High";
      default:
        return "Unknown";
    }
  };

  const mapCategory = (value) => {
    switch (value) {
      case 0:
        return "Mini";
      case 1:
        return "Junior";
      case 2:
        return "Senior";
      case 3:
        return "Shifter";
      default:
        return "Unknown";
    }
  };

  const mapGroup = (value) => {
		switch (parseInt(value)) {
			case 1:
				return "1";
			case 2:
				return "2";
			case 3:
				return "3";
      case 4:
        return "4";
      case 5:
        return "A";
      case 6:
        return "B";
      case 7:
        return "C";
      case 8:
          return "D";
      case 9:
          return "E";
      case 10:
          return "F";
      case 11:
          return "G";
      case 12:
          return "H";

      default:
				return "Unknown";
		}
  }
  const mapperYN = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];

  const mapCarburetor = (value) => {
    switch (parseInt(value)) {
      case 1:
        return "Tilltson (Junior)";
      case 2:
        return "Ibea F3";
      case 3:
        return "Ibea F6";
      case 4:
        return "TM";
      case 5:
        return "Tilltson New (Senior)";
      case 6:
        return "Tilltson Old (Senior)";
      default:
        return "Unknown";
    }
  };

  return (
    <div>
      <Container>
      <Col xs={12} className="mb-3">
						<h3>
							Analysis
						</h3>
					</Col>
      <Row>
    
    <Col xs={12} sm={6} md={4}>
    </Col>
    <Col xs={12} sm={6} md={4}>
    <Form.Group className="mb-3">
      <Form.Label>Select Category:</Form.Label>
      <Form.Select
        name="category"
        value={category}
        onChange={handleChange}
        required
      >
        <option value="">Category</option>
        <option value="1">Mini</option>
        <option value="2">Junior</option>
        <option value="3">Senior</option>
        <option value="4">Shifter</option>
      </Form.Select>
    </Form.Group>
    </Col>

    <Col xs={12} sm={6} md={4}>
    <Form.Group className="mb-3">
      <Form.Label>Select The Day:</Form.Label>
      <Form.Select
        name="day"
        value={day}
        onChange={handleChange}
        required
      >
        <option value="">Racing Day</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
       
      </Form.Select>
    </Form.Group>
    </Col>

      </Row>
    <Row>
    {renderTables()}
    </Row>
    <Col xs={12} className="mb-3">
            <h3>
              Graphs
            </h3>
          </Col>
    
     
    
      </Container>
    
  </div>
  

    
  );
};

export default SessionsByDateAndGroup;




