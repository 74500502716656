import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./components.css";
import { Container, Row, Col, Table, Modal, Button } from "react-bootstrap";
import axios from "axios";
import Form from "react-bootstrap/Form";
import dayjs from "dayjs";
import { IoMdCreate } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import EditableCell from "./EditableCell";
import EditableCellSelect from "./EditableCellSelect";
import EditableCellNumeric from "./EditableCellNumeric";
import { MdDelete } from "react-icons/md";

const ViewDataComponent = () => {
  const mapperYN = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];
  const history = useHistory();
  const [racingData, setRacingData] = useState([]);
  const [driver, setDriver] = useState("All");
  const [drivers, setDrivers] = useState(["All"]);
  const [track, setTrack] = useState("All");
  const [tracks, setTracks] = useState(["All"]);
  const [sessionType, setSessionType] = useState("All");
  const [sessionTypes, setSessionTypes] = useState(["All"]);
  const [filteredData, setFilteredData] = useState([]);
  const [id, setId] = useState(-1);
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("access_token");

  useEffect(() => {
    const params = JSON.parse(localStorage.getItem("user_data"));
    const apiUrl = `https://aimotor.eu/api/get_total_race_data/`;
  
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data !== null) {
          setRacingData(data);
        }
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  
    // Add console log here
    console.log("Effect executed");
  
  }, [show]);
  
  useEffect(() => {
    setTracks(["All", ...new Set(racingData.map((data) => data.track))]);
    setSessionTypes([
      "All",
      ...new Set(racingData.map((data) => data.session_type)),
    ]);
    setDrivers(["All", ...new Set(racingData.map((data) => data.full_name))]);
  }, [racingData]);

  useEffect(() => {
    filterData();
  }, [racingData, driver, track, sessionType]);

  const filterData = () => {
    // Apply filters based on track, driver, and sessionType
    if (racingData) {
      let startData = racingData.slice();
      if (track !== "All") {
        startData = startData.filter((data) => data.track === track);
      }

      if (driver !== "All") {
        startData = startData.filter((data) => data.full_name === driver);
      }

      if (sessionType !== "All") {
        startData = startData.filter(
          (data) => data.session_type === sessionType
        );
      }
      setFilteredData(startData);
    }
  };

  const mapHeight = (value) => {
    switch (value) {
      case 0:
        return "Low";
      case 1:
        return "Medium";
      case 2:
        return "High";
      default:
        return "Unknown";
    }
  };

  const mapTiming = (value) => {
    switch (parseInt(value)) {
      case 0:
        return "Too early";
      case 1:
        return "Normal";
      case 2:
        return "Too late";
      default:
        return "Unknown";
    }
  };

  const mapHardness = (value) => {
    switch (parseInt(value)) {
      case 0:
        return "Soft";
      case 1:
        return "Medium";
      case 2:
        return "Hard";
      case 3:
        return "Rain";
      default:
        return "Unknown";
    }
  };
  const mapGroup = (value) => {
    switch (parseInt(value)) {
      case 1:
        return "1";
      case 2:
        return "2";
      case 3:
        return "3";
      case 4:
        return "4";
      case 5:
        return "A";
      case 6:
        return "B";
      case 7:
        return "C";
      case 8:
        return "D";
      case 9:
        return "E";
      case 10:
        return "F";
      case 11:
        return "G";
      case 12:
        return "H";

      default:
        return "Unknown";
    }
  };

  const mapCarburetor = (value) => {
    switch (parseInt(value)) {
      case 1:
        return "Tilltson (Junior)";
      case 2:
        return "Ibea F3";
      case 3:
        return "Ibea F6";
      case 4:
        return "TM";
      case 5:
        return "Tilltson New (Senior)";
      case 6:
        return "Tilltson Old (Senior)";

      default:
        return "Unknown";
    }
  };

  const updateValue = (value, field, id) => {
    // query to update
    axios
      .post(
        `https://aimotor.eu/api/change_data/?value=${value}&field=${field}&id=${id}`,
        //http://104.248.45.175:8000
        // http://127.0.0.1:8000
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        const userData = userResponse.data;
      })
      .catch((error) => {
        toast.error("Failed to update the value!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const deleteValue = (id) => {
    // query to update
    axios
      .post(
        `https://aimotor.eu/api/delete_data/?id=${id}`,
        //http://104.248.45.175:8000
        // http://127.0.0.1:8000
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        toast.success("Entry deleted succesfully!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        handleClose();
        // window.location.reload();
      })
      .catch((error) => {
        toast.error("Failed to delete the entry!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => deleteValue(id)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Form>
        <Row>
          <Col xs={12} className="mb-3">
            <h3>
              <Col xs={12} className="mb-3">
                <h3>
                  If you made a mistake, you can modify the data you entered by
                  clicking on the cell containing the information you want to
                  change
                </h3>
              </Col>
            </h3>
          </Col>
          {JSON.parse(localStorage.getItem("user_data")).role == "MGR" && (
            <Col md={4} sm={6} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="circuitSelect">Select Driver: </Form.Label>
                <Form.Select
                  id="circuitSelect"
                  value={driver}
                  onChange={(event) => setDriver(event.target.value)}
                >
                  {drivers.map((drv) => (
                    <option key={drv} value={drv}>
                      {drv}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          )}

          <Col md={4} sm={6} xs={12}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="circuitSelect">Select Circuit: </Form.Label>
              <Form.Select
                id="circuitSelect"
                value={track}
                onChange={(event) => setTrack(event.target.value)}
              >
                {tracks.map((circuit) => (
                  <option key={circuit} value={circuit}>
                    {circuit}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={6} md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="sessionTypeSelect">
                Select Session Type:{" "}
              </Form.Label>
              <Form.Select
                id="sessionTypeSelect"
                value={sessionType}
                onChange={(event) => setSessionType(event.target.value)}
              >
                {sessionTypes.map((ses) => (
                  <option key={ses} value={ses}>
                    {ses}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {/* <Col md={6} lg={4}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="sessionSelect">Select Session Name: </Form.Label>
              <Form.Select id="sessionSelect" value={selectedSession} onChange={handleSessionChange}>
                {getSessionOptions().map((session) => (
                  <option key={session} value={session}>
                    {session}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col> */}
        </Row>
      </Form>

      {filteredData.length > 0 ? (
        <Table responsive striped bordered hover className="sticky-thc">
          <thead>
            {/* <th>Edit</th> */}
            <tr>
              <th></th>
              <th>Date</th>
              <th>Race</th>
              <th>Driver</th>
              <th>Lap Time</th>
              <th className="td-large">Driver Comments</th>
              <th>Chassis Number</th>
              <th>Engine Number</th>
              <th>Carburetor</th>

              <th>Tires New</th>

              <th>Hot Pressure</th>
              <th>Cold Pressure</th>
              <th>Max Exhaust</th>
              <th>Min Exhaust</th>
              <th>Crown</th>
              <th>Temperature</th>
              <th>Max Speed</th>
              <th>Max RPM</th>
              <th>Pinion</th>

              <th>Caster</th>
              <th>Camber</th>
              <th>Turn Entry</th>
              <th>Oversteer</th>
              <th>Understeer</th>
              <th>Category</th>
              <th>Session Type</th>
              <th>Session Number</th>
              <th>Session Group</th>
              {/* <th>Category</th>
              <th>Tires Type</th> */}
              <th>Rain</th>
              <th>Axle Type</th>
              <th>Axle Position</th>
              <th>Slip</th>
              <th>Heaviness</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((entry, index) => (
              <tr key={index}>
                <td style={{ verticalAlign: "middle" }}>
                  <MdDelete
                    size="1.5rem"
                    onClick={() => handleShow(entry.id)}
                  />
                </td>
                <EditableCell
                  value={dayjs(entry.timestamp_location).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                  onSave={updateValue}
                  field="timestamp"
                  id={entry.id}
                />
                <td>
                  {entry.competition} ({entry.track})
                </td>
                <td>{entry.full_name}</td>
                <EditableCell
                  value={entry.laptime}
                  onSave={updateValue}
                  field="laptime"
                  id={entry.id}
                />
                <td>{entry.comment}</td>
                <EditableCell
                  value={entry.chassis_number}
                  onSave={updateValue}
                  field="chassis_number"
                  id={entry.id}
                />
                <EditableCell
                  value={entry.engine_number}
                  onSave={updateValue}
                  field="engine_number"
                  id={entry.id}
                />
                <td>{mapCarburetor(entry.carburetor)}</td>
                <EditableCellSelect
                  value={entry.tires_new}
                  onSave={updateValue}
                  field="tires_new"
                  id={entry.id}
                  vals={mapperYN}
                />
                {/* <td>{entry.tires_new == 1 ? "Yes" : "No"}</td> */}
                <EditableCellNumeric
                  value={entry.pressure_hot}
                  onSave={updateValue}
                  field="pressure_hot"
                  id={entry.id}
                  min="0"
                  max="500"
                />
                <EditableCellNumeric
                  value={entry.pressure_cold}
                  onSave={updateValue}
                  field="pressure_cold"
                  id={entry.id}
                  min="0"
                  max="500"
                />
                <td>{entry.max_exhaust}</td>
                <td>{entry.min_exhaust}</td>
                <EditableCellNumeric
                  value={entry.crown}
                  onSave={updateValue}
                  field="crown"
                  id={entry.id}
                  min="0"
                  max="150"
                />
                {/* <td>{entry.crown}</td> */}
                <EditableCellNumeric
                  value={entry.temperature}
                  onSave={updateValue}
                  field="temperature"
                  id={entry.id}
                  min="-60"
                  max="60"
                />
                <EditableCellNumeric
                  value={entry.max_speed}
                  onSave={updateValue}
                  field="max_speed"
                  id={entry.id}
                  min="0"
                  max="200"
                />
                <td>{entry.max_rpm}</td>
                <EditableCellNumeric
                  value={entry.pinion}
                  onSave={updateValue}
                  field="pinion"
                  id={entry.id}
                  min="0"
                  max="20"
                />
                <EditableCellNumeric
                  value={entry.caster}
                  onSave={updateValue}
                  field="caster"
                  id={entry.id}
                  min="-60"
                  max="60"
                />
                <EditableCellNumeric
                  value={entry.camber}
                  onSave={updateValue}
                  field="camber"
                  id={entry.id}
                  min="-60"
                  max="60"
                />
                {/* <td>{entry.camber}</td> */}
                <td>{mapTiming(entry.curve_enter)}</td>
                <EditableCellSelect
                  value={entry.oversteer}
                  onSave={updateValue}
                  field="oversteer"
                  id={entry.id}
                  vals={mapperYN}
                />
                <EditableCellSelect
                  value={entry.understeer}
                  onSave={updateValue}
                  field="understeer"
                  id={entry.id}
                  vals={mapperYN}
                />
                <td>{entry.category}</td>
                <td>{entry.session_type}</td>
                <td>{entry.session_number}</td>
                <td>{mapGroup(entry.session_group)}</td>

                {/* <EditableCellSelect
                  value={entry.session_group}
                  onSave={updateValue}
                  field="session group"
                  id={entry.id}
                  vals={mapperYN}
                /> */}

                {/* <td>{entry.category}</td> */}
                {/* <td>{mapHardness(entry.tires_type)}</td> */}
                <EditableCellSelect
                  value={entry.rain}
                  onSave={updateValue}
                  field="rain"
                  id={entry.id}
                  vals={mapperYN}
                />

                <td>{entry.axle_type}</td>
                <td>{mapHeight(entry.axle_position)}</td>
                <EditableCellSelect
                  value={entry.slip}
                  onSave={updateValue}
                  field="slip"
                  id={entry.id}
                  vals={mapperYN}
                />
                <EditableCellSelect
                  value={entry.heaviness}
                  onSave={updateValue}
                  field="heaviness"
                  id={entry.id}
                  vals={mapperYN}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data to display.</p>
      )}
    </Container>
  );
};

export default ViewDataComponent;
