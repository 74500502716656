import { Box, Skeleton, Stack } from '@mui/material';
import { Button, Chip, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChatIcon from '@mui/icons-material/Chat';
import ChatSuggestion from '../Suggestions';
import DOMPurify from 'dompurify';
import Drawer from './Drawer';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Grid from '@mui/material/Grid';
import HttpProvider from 'services/HttpProvider'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Markdown from 'marked-react';
import Paper from '@mui/material/Paper';
import Pricing from './Pricing';
import SettingsIcon from '@mui/icons-material/Settings';
import StarIcon from '@mui/icons-material/Star';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { all } from 'axios';
import { marked } from 'marked';
import myUserStore from '../../stores/UserStore';
import { observer } from "mobx-react-lite"
import { v4 as uuidv4 } from 'uuid';
import Tooltip from '@mui/material/Tooltip';

const chatTypes = [
    {
        title: 'AiMotor 1',
        description: 'Great for Personal Space',
        type: 'normal',
        icon: <FlashOnIcon fontSize="small" />,
        activeFor: ['Growth', 'Pro', 'Team'], 
        userPlan: ['Growth'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day:1,
            day_team:1,
        }
    },
    {
        title: 'AiMotor 2',
        description: 'Advanced model for Racing Teams',
        type: 'advanced',
        icon:  <StarIcon fontSize="small" />,
        activeFor: ['Pro', 'Team'], 
        userPlan: ['Growth', 'Pro', 'Team'],
        queryParams: {
            personal_retrieval_limit: 0,
            team_retrieval_limit: 0,
            day:1,
            day_team:1,
        }
    }
]

const Chat = () => {
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [allChatMessages, setAllChatMessages] = useState({});
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [openPricing, setOpenPricing] = useState(false);
    const [isFirstMessageSent, setIsFirstMessageSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chatType, setChatType] = useState(chatTypes[0])
    const selectedTeam = myUserStore.getSelectedTeam;

    // const userPlan = myUserStore.planCode
    const userPlan=  "Team"

  
    const chatContainerRef = useRef(null);

    // check into chatTypes for userPlan
    const getChatByPlan = (userPlan) => {
        const getChatToBeSeleced = chatTypes.find(c => c.userPlan.includes(userPlan))
        if (getChatToBeSeleced) {
            setChatType(getChatToBeSeleced)
        } else {
            setChatType(chatTypes[0])
        }
    }

    // check into chatTypes for activeFor
    const handleMenuItemClick = (model) => {
        // check if user can select this model
        const getChatToBeSeleced = chatTypes.find(c => c.title === model)

        if (getChatToBeSeleced) {
            const canSelect = getChatToBeSeleced.activeFor.includes(userPlan)
            if (canSelect) {
                setChatType(getChatToBeSeleced)
                handleClose();
            } else {
                // show pricing
                setOpenPricing(true)
            }
        }
    };

    useEffect(() => {
        if (!selectedChatId) {
            // handleNewChat();
            handleLatestChat()
        } else {
            getChatById(selectedChatId);
        }
    }, [selectedChatId]);

    useEffect(() => {
        getChatByPlan(userPlan)
    }, [userPlan])

    const getChatById = async (chatId) => {
        const response = await fetch(`https://aimotor.eu/api/get_conversations/?chat_id=${chatId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        const data = await response.json();
        setAllChatMessages(prevMessages => ({ ...prevMessages, [chatId]: { name: '', messages: data.messages, createdAt: new Date() } }));
        return data;
    };

    const generateChatId = async () => {
        const response = await fetch(`https://aimotor.eu/api/create_conversation`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        const data = await response.json();
        const conversation_id = data.conversation_id;
        return conversation_id;
    };

    const getlatestchat = async () => {
        const response = await fetch(`https://aimotor.eu/api/get_last_conversation/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });
        let data
        if (response?.ok) {
            data = await response.json();
        } else {
            // in case of error call generateChatId
            // to generate a new id
            // for now is handled by FE, is good to be handled by SERVER
            data = { _id: await generateChatId() }
        }
        return data;
    };

    const handleMessageSubmit = async (message) => {
        if (!selectedChatId) return;
        const encodedMessage = encodeURIComponent(message)
        const newMessage = { message: message, role: "user" };

        setAllChatMessages(prevMessages => {
            const updatedChat = prevMessages[selectedChatId];
            const updatedMessages = [...updatedChat.messages, newMessage];
    
            if (!isFirstMessageSent) {
                setIsFirstMessageSent(true);
                return {
                    ...prevMessages,
                    [selectedChatId]: { ...updatedChat, name: encodedMessage, messages: updatedMessages }
                };
            } else {
                return {
                    ...prevMessages,
                    [selectedChatId]: { ...updatedChat, messages: updatedMessages }
                };
            }
        });
    
        // Scroll to bottom
        setTimeout(() => {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }, 0);
    
        try {
            setIsLoading(true);
            const chatUri = chatType.type === 'normal' ? 'query_google_normal' : 'aimotor_2';
            const response = await HttpProvider.post(`/${chatUri}`, { query: { request: encodedMessage } }, {
                params: {
                    request: encodedMessage,
                    conversation_id: selectedChatId,
                    team_id: selectedTeam.id,
                    ...chatType.queryParams
                },
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    accept: "application/json",
                },
            })
                .then((response) => {
                    let res = response.data;
                    const cleanedData = res.replace(/\\n/g, '\n');
    
                    setAllChatMessages(prevMessages => {
                        const updatedChat = prevMessages[selectedChatId];
                        const updatedMessages = [...updatedChat.messages, { message: decodeURIComponent(cleanedData), role: "system" }];
                        return {
                            ...prevMessages,
                            [selectedChatId]: { ...updatedChat, messages: updatedMessages }
                        };
                    });
    
                    // Scroll to bottom
                    setTimeout(() => {
                        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                    }, 0);
                    setIsLoading(false);
                });
        } catch (error) {
            setIsLoading(false);
            console.error("Error querying LLM:", error);
        }
    };

    const ScrollChatBottom = useCallback(() => {
        const anchorEl = chatContainerRef.current;

        if (anchorEl) {
            anchorEl.scrollTop = anchorEl.scrollHeight - anchorEl.clientHeight;
        }
    }, [allChatMessages?.messages?.length, chatContainerRef])


    const handleNewChat = async () => {
        const newChatId = await generateChatId();
        setAllChatMessages(prevMessages => ({ ...prevMessages, [newChatId]: { name: '', messages: [], createdAt: new Date() } }));
        setSelectedChatId(newChatId);
        setIsFirstMessageSent(false);
    };


    const handleLatestChat = async () => {
        const { _id, messages = [] } = await getlatestchat();
        setAllChatMessages(prevMessages => ({ ...prevMessages, [_id]: { name: '', messages, createdAt: new Date() } }));
        setSelectedChatId(_id);
        setIsFirstMessageSent(false);
        ScrollChatBottom(3000);
    };

    const handleSuggestionClick = (message) => {
        handleMessageSubmit(message)

    };

    const handleSelectPreviousChat = (chatId) => setSelectedChatId(chatId);

    useEffect(() => {
        ScrollChatBottom()
    }, [ScrollChatBottom]);


    useEffect(() => {
        if (selectedChatId && chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [selectedChatId, allChatMessages]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    console.log(selectedChatId)



    return (
        <>
            <Box className="chat-wrapper" container={true} justifyContent="center">
                <Box sx={{ position: 'absolute', top: '16px', left: '16px', display: 'flex', alignItems: 'center' }}>
                    <Button
                        aria-controls={open ? 'version-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant="text"
                        sx={{ color: '#0D47A1', textTransform: 'none', fontSize: '22px', fontWeight: 'bold' }} // Adjust font size and weight
                    >
                        {chatType.title}
                        <ArrowDropDownIcon />
                    </Button>
                    <Menu
                        id="version-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        sx={{ mt: 1 }}
                    >
                        {chatTypes.map((type) => (
                            <MenuItem key={type.title} onClick={() => handleMenuItemClick(type.title)}>
                                <ListItemIcon>
                                    {type.icon}
                                </ListItemIcon>
                                <ListItemText primary={type.title} secondary={type.description} />
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
                <Box sx={{ height: "100%", width: '100%', pt: 10 }} display={'flex'} flexDirection={'column'} mx={'auto'}>
                    <div id="chat-container" style={{ flex: '1', overflowY: 'auto', textAlign: 'center', display: selectedChatId && allChatMessages[selectedChatId]?.messages?.length ? 'none' : 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box>
                            <ChatSuggestion onClick={handleSuggestionClick} />
                        </Box>
                    </div>
                    <div
                        style={{ flex: '1', overflowY: 'auto', display: selectedChatId && allChatMessages[selectedChatId]?.messages?.length ? 'block' : 'none' }}
                        ref={chatContainerRef}
                    >
                        <Box sx={{
                            maxWidth: '800px',
                            margin: '0 auto'
                        }}>
                            {(allChatMessages[selectedChatId]?.messages || []).map((msg, index) => (
                                <div key={index} style={{ marginBottom: '10px' }}>
                                    <Grid container textAlign={msg.role === "user" ? "end" : "start"} alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography variant="caption" style={{ textAlign: msg.role === "user" ? 'right' : 'left', marginBottom: '5px', color: 'black' }}>{msg.role === "user" ? 'You' : 'AiMotor'}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                p: 2,
                                                backgroundColor: '#333333',
                                                borderRadius: 2,
                                                whiteSpace: "pre-line",
                                                color: 'white',
                                                mb: 1,
                                                overflow: 'auto',
                                                display: 'inline-block',
                                                maxWidth: {
                                                    xs: '90%',
                                                    md: '80%'
                                                }
                                            }}>
                                                <Markdown>
                                                    {msg.message}
                                                </Markdown>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        </Box>
                        {isLoading && (
                            <Stack direction="row" spacing={2} alignItems={'start'}>
                                <Skeleton animation="wave" variant="circular" width={32} height={32} />
                                <Skeleton
                                    variant='h1'
                                    animation="wave"
                                    height={80}
                                    width="50%"
                                    sx={{
                                        borderRadius: '8px'
                                    }}
                                />
                            </Stack>
                        )}
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            const message = e.target.elements.message.value;
                            if (message.trim() !== '') {
                                handleMessageSubmit(message)
                                e.target.reset();
                            }
                        }}
                        style={{ display: 'flex', alignItems: 'center', position: 'relative', bottom: 0, padding: '10px', zIndex: 2, width: '100%', maxWidth: '800px', margin: '0 auto' }}
                    >
                        <Paper
                            sx={{ p: '4px', borderRadius: "50px", display: 'flex', alignItems: 'center', width: '100%' }}
                        >
                            <Tooltip title="previous chat history"> {/* Add Tooltip here */}
                                <IconButton 
                                    onClick={() => setIsDrawerOpen(true)} 
                                    sx={{ color: 'white', backgroundColor: '#0D47A1', '&:hover': { backgroundColor: '#1565C0' }, padding: '6px' }} // Adjust size and padding
                                >
                                    <ChatIcon sx={{ fontSize: '20px' }} /> {/* Adjust icon size */}
                                </IconButton>
                            </Tooltip>
                            <TextField
                                fullWidth
                                placeholder="Message AiMotor"
                                variant="standard"
                                name="message"
                                multiline
                                InputLabelProps={{
                                    style: { color: 'black', textAlign: 'center', width: '100%' },
                                    shrink: true,
                                }}
                                InputProps={{
                                    style: { color: 'black', textAlign: 'center' },
                                    disableUnderline: true,
                                }}
                                sx={{ textAlign: 'center' }}
                            />
                            <IconButton
                                color="primary"
                                type="submit"
                                style={{ minWidth: 'auto' }}
                            >
                                <ArrowUpwardIcon />
                            </IconButton>
                        </Paper>
                    </form>
             
                </Box>
                <Drawer
                    onNewChat={handleNewChat}
                    handleChatSelection={handleSelectPreviousChat}
                    isOpen={isDrawerOpen}
                    onClose={() => setIsDrawerOpen(false)}
                />

                <Pricing open={openPricing} onClose={() => setOpenPricing(false)} />
            </Box>
        </>
    );
};

 export default Chat;;