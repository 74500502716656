import { Box, Button, Chip, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import GoFasterHero from 'components/Hero/GoFaster';
import ImageTextCard from 'components/Card/ImageText';
import React from 'react';
import Infinitescroll from "../Infinitescroll";
const VellumComponent = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const items = ['What is the recommended braking point for the second corner at the Lonato karting circuit for optimal performance?', 'I am experiencing understeer. Should I increase the camber angle to correct it?', 'Can you help me understand why my teammate is achieving faster lap times than I am?', 'I am unsure if changing the sprocket is the right decision for qualifying. Considering my racing data from the previous day, what do you suggest?', 'Considering my recent performance data, what recommendations do you have for improvement?'];

  return (

    <Box
      sx={{
        backgroundColor: 'black',
        color: '#fff',
        textAlign: 'center',
        padding: '40px 20px 0',
        minHeight: '100vh',
      }}
    >
      <Typography
        variant="h2"
        component="div"
        gutterBottom
        sx={{
          fontSize: '2.5rem',
          fontWeight: 'bold'
        }}
      >
        Improve with{' '}
        <Typography
          component="span"
          sx={{
            color: '#5865f2',
            fontSize: '2.5rem',
            fontWeight: 'bold'
          }}
        >
          AiMotor Chatbot
        </Typography>
      </Typography>
      <Stack direction="row" spacing={1} mb={10} justifyContent={'center'}>
        <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" color='primary' clickable /*sx={{color: 'white' }}*/ />
        <Chip label="For Racing Teams" component="a" href='#For Team Managers' variant="outlined" clickable sx={{ color: 'white' }} />
      </Stack>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box textAlign="left">
            <Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: 'bold', paddingBottom: '8px' }}>Discover</Typography>
            <Typography variant="body1" gutterBottom>
              Step-by-Step Guide to Master Every Go-Kart Adjustment.
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: 'bold', paddingTop: '16px', paddingBottom: '8px' }}>Master</Typography>
            <Typography variant="body1" gutterBottom>
              AI-Driven Coaching: Elevate Your Driving Style.
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: 'bold', paddingTop: '16px', paddingBottom: '8px' }}>Work out</Typography>
            <Typography variant="body1" gutterBottom>
              Input Your Data, Let the Model Learn, and Discover Optimal Solutions for Every Technical Issue.
            </Typography>
            <Typography variant="h6" sx={{ fontSize: '1.2rem', fontWeight: 'bold', paddingTop: '16px', paddingBottom: '8px' }}>Compare</Typography>
            <Typography variant="body1" gutterBottom>
              Let the Model Analyze Your Setups and Compare them with Your Previous Data for Optimal Insights.
            </Typography>
          </Box>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                width: '400px',
                height: '400px',
                margin: '0 auto',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  border: '2px solid #5865f2',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  fontSize: '1.7em',
                  color: '#fff',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    width: '280px',
                    height: '280px',
                    border: '2px solid #5865f2',
                    borderRadius: '50%',
                    boxSizing: 'border-box',
                    transform: 'rotate(45deg)',
                  },
                }}
              >
                AiMotor generative AI
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '8.5%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '0.8em',
                  textAlign: 'center',
                }}
              >
                Compare
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '7.5%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '0.8em',
                  textAlign: 'center',
                }}
              >
                Discover
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '92%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '0.8em',
                  textAlign: 'center',
                }}
              >
                Master
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: '7.5%',
                  transform: 'translate(50%, -50%)',
                  fontSize: '0.8em',
                  textAlign: 'center',
                }}
              >
                Work out
              </Box>
            </Box>
          </Grid>
        )}

        <Grid item md={10}>
          <ImageTextCard
            imageUrl={'images/aimotor1-min.jpg'}
            imageAlt='Great for giving users setup advice based on their own data. Also trained to answer general questions about driving style, manage your setup Data, and provide step-by-step guides to master every go-kart adjustment.'
            title={
              <Typography
                component="span"
                sx={{
                  color: '#5865f2',
                  fontSize: '2.5rem',
                  fontWeight: 'bold'
                }}
              >
                AiMotor 1
              </Typography>
            }
            description="Great for giving users setup advice based on their own data. Also trained to answer general questions about driving style and provide step-by-step guides to master every go-kart adjustment."
            sx={{
              textAlign: 'left',
              height: { lg: '280px'},
            }}
          />
        </Grid>


      </Grid>
      <Stack direction="row" spacing={1} sx={{ flexDirection: "row", justifyContent: "center", marginBottom: 2 }} mt={8}>
        <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" clickable sx={{ color: 'white' }} />
        <Chip label="For Racing Teams" id="For Team Managers" component="a" href='#For Team Managers' variant="outlined" color='primary' clickable />
      </Stack>


      {/* New Section */}
      <Typography
        variant="h4"
        component="div"
        gutterBottom
        sx={{
          fontSize: '2rem',
          fontWeight: 'bold',
          marginTop: '40px',
        }}
      >
        Teams go faster with AiMotor
      </Typography>
      <Typography
        variant="body1"
        component="div"
        gutterBottom
        sx={{
          fontSize: '1rem',
          marginBottom: '20px',
        }}
      >
        All Data Entered by Team Members Will Be Understood by the Model
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ marginTop: '20px' }}>
        <Grid item md={10}>
          <ImageTextCard
            imageUrl={'images/aimotor2-min.jpg'}
            imageAlt='Our most trained model. Outstanding at discovering optimal solutions for every technical issue by comparing your data with your teammates'
            title={
              <Typography
                component="span"
                sx={{
                  color: '#5865f2',
                  fontSize: '2.5rem',
                  fontWeight: 'bold'
                }}
              >
                AiMotor 2
              </Typography>
            }
            description="Our most trained model. Outstanding at discovering optimal solutions for every technical issue by comparing your data with your teammates"
            sx={{
              textAlign: 'left',
              height: { lg: '280px'},
            }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: 'center', padding: '20px', backgroundColor: '#333', borderRadius: '10px' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#fff' }}>Work out</Typography>
            <Typography variant="body1" sx={{ color: '#fff' }}>Technical Suggestions Will Also Be Also Based on Your Teammates' Data</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: 'center', padding: '20px', backgroundColor: '#333', borderRadius: '10px' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#fff' }}>Compare</Typography>
            <Typography variant="body1" sx={{ color: '#fff' }}>The Model Analysis Will Include Comparisons with Your Teammates.</Typography>
          </Box>
        </Grid>
      </Grid>

  

 <Infinitescroll/>
      <GoFasterHero />
    </Box>
  );
};

export default VellumComponent;
