import "react-toastify/dist/ReactToastify.css";

import { Box, Button, CircularProgress, Container, Typography } from '@mui/material'
import React, { useState } from "react"

import HttpProvider from 'services/HttpProvider'
import NewLogo from 'components/NewLogo.png'
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ActivateAccount({ }) {

  const [loading, setLoading] = useState(false)
  const history = useHistory();
  let query = useQuery();
  let token = query.get('token');

  const getUser = (access_token) => {
    return HttpProvider.get("/users/me/", {
      headers: {
        Authorization: `Bearer ${access_token}`,
        accept: "application/json",
      },
    }).then((userResponse) => {
      const userData = userResponse.data;
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("user_data", JSON.stringify(userData));
      return userData
    })
  }

  const activateAccount = () => {
    setLoading(true)
    return HttpProvider.post('/activate_account/', null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        accept: "application/json",
      }
    })
      .then((res) => {
        // redirect to login
        // the user is not enabled
        toast.success("Account successfully activated!", {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
				});
        return getUser(token).then(() => history.push("/sign-in"))
      })
      .catch((err) => {
        toast.error("Something went wrong!", {
					position: "top-right",
					autoClose: 2000,
					hideProgressBar: false,
				});
        console.error('something went wrong', err)
        return err
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Container
      maxWidth={false}
      style={{
        textAlign: "center",
        backgroundColor: "#000",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            p: 4,
            backgroundColor: "#1c1c1e",
            borderRadius: 2,
            maxWidth: 400,
            width: "100%",
            margin: "0 auto"
          }}
        >
          <Typography variant="h4" className='gradient-text'>Activate your account</Typography>
          <Button fullWidth variant="contained" color="secondary" size={"large"} onClick={activateAccount} sx={{
            my: 6,
            alignItems: 'center'
          }}
          disabled={!token}>
            Activate account
            <Box ml={2} hidden={!loading} display={'flex'} alignItems={'center'}>
              <CircularProgress size={26} color='light' />
            </Box>
          </Button>
          <Box textAlign={'center'}>
            <img src={NewLogo} alt="AiMotors logo" width="120px"  />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}