import { Box, Grid, Stack, Typography } from '@mui/material'

export default function ImageTextCard ({ imageUrl, imageAlt = '', title, description, invert = false, ...props }) {
  return (
    <Box
      {...props}
      sx={{
        backgroundColor: 'rgb(34, 34, 34)',
        borderRadius: 2,
        overflow: 'hidden',
        height: { lg: '300px'},
        width: '100%',
        ...(props.sx || {})
      }}
      
    >
      <Grid container alignItems={'center'} sx={{ height: '100%' }}>
        <Grid item md={6} order={{ xs: 1, lg: invert ? 2 : 1 }}>
          <img src={imageUrl} alt={imageAlt || title} className='img-fluid' />
        </Grid>
        <Grid item md={6} order={{ xs: 2, lg: invert ? 1 : 2 }}>
          <Stack spacing={1} py={4} px={{ xs: 2, md: 6 }} alignItems={'start'} justifyContent={'center'} color='#fff'>
            <Typography fontSize={'24px'} fontWeight={700}>{title}</Typography>
            <Typography fontSize={'16px'}>{description}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}