import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Col, Form, FormGroup, Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

function ResetPasswordForm() {
  const location = useLocation();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // setAccessToken();
    // console.log('ACCESS TOKEN');
    console.log(searchParams.get("access_token"));
    // http://localhost:3000/
    // axios.get('http://localhost:8000/users/me', {
    axios
      .get("https://aimotor.eu/api/users/me/", {
        headers: {
          Authorization: `Bearer ${searchParams.get("access_token")}`, // Include the access token in the header
          accept: "application/json",
        },
      })
      .then((userResponse) => {
        const userData = userResponse.data;
      })
      .catch((error) => {
        toast.error("Token is not valid!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        history.push("/");
        console.error("Query Error:", error);
      });
  }, [location.search, history]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams(location.search);
    axios
      .post(
        `https://aimotor.eu/api/change_password/?password=${password}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${searchParams.get("access_token")}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        const userData = userResponse.data;
        const token = userData.accessToken;
        toast.success("Password updated correctly!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        history.replace("/sign-in");
        // window.location.reload();
      });
  };

  return (
    <Col md={{ span: 6, offset: 3 }} className="mt-4">
      <h2 className="mb-4">Reset Password</h2>
      <Form onSubmit={handleFormSubmit}>
        <FormGroup className="mb-4">
          <Form.Label htmlFor="password">Insert the New Password:</Form.Label>
          <Form.Control
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </FormGroup>
        <FormGroup className="mb-4">
          <Button type="submit" variant="primary">
            Reset Password
          </Button>
        </FormGroup>
      </Form>
    </Col>
  );
}

export default ResetPasswordForm;
