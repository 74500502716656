import { Box, Button, Grid, Stack, Typography } from '@mui/material';

import BuildIcon from '@mui/icons-material/Build';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
const suggestionLegend = [
  {
    icon: <BuildIcon />,
    text: 'Mounting your kart axle',
    detaliedMessage: "As a professional karting mechanic, explain how to properly mount the axle on a kart.",
  },
  {
    icon: <ElectricBoltIcon />,
    text: 'Fixing understeer',
    detaliedMessage: "As a professional karting mechanic, explain how to reduce on my Kart the understeer.",
  },
  {
    icon: <SettingsIcon />,
    text: 'Improving my setup',
    detaliedMessage: "As a professional karting mechanic, explain how to improve my setup based on the data I provided.",
    hideMobile: true
  },
  {
    icon: <SpeedIcon />,
    text: 'Unsure about changing the sprocket for qualifying',
    detaliedMessage: "I'm unsure if changing the sprocket is the right decision for qualifying. Considering my racing data from the previous day, what do you suggest?",
    hideMobile: true
  }
];

function ChatSuggestion({ onClick = () => { } }) {
  return (
    <Grid container spacing={2}>
      {suggestionLegend.map((card, key) => (
        <Grid
          item
          xs={6}
          lg={3}
          key={key}
          display={{
            xs: card.hideMobile ? 'none' : 'block',
            lg: 'block'
          }}
        >
          <Box
            sx={{
              borderRadius: '16px',
              padding: '12px 12px 16px 12px',
              backgroundColor: '#fff',
              border: '1px solid #5151511a',
              height: '150px' // Set a fixed height to ensure all boxes have the same dimension
            }}
            position="relative"
            overflow="hidden"
          >
            <Button
              onClick={() => onClick(card.detaliedMessage)}
              variant="text"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                bottom: 0
              }}
            ></Button>
            <Stack spacing={1} alignItems="center">
              {card.icon}
              <Typography variant="body1">
                {card.text}
              </Typography>
            </Stack>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

export default ChatSuggestion;
