import React from 'react';
import './components.css';
import { Row, Col } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import SocialManger from '../img/SocialManger.svg';
import AnalysisManager from '../img/AnalysisManager.svg';
import control from '../img/control.svg';
import implementnewsetups from '../img/implementnewsetups.svg';
import Community from '../img/Community.svg';

function HowItWorksManager() {
  return (
    <Container fluid className="howitworks-container">
      {/* First Section */}
      <Row className='full-width'>
      <h1> <span className="highlight2">Manager</span> </h1>
        <Col sm={6} xs={12} className="d-flex justify-content-center align-items-center">
          <Image className='clipped-image' src={Community} alt="Insert Data" height="380" />
        </Col>
        <Col sm={6} xs={12} className="d-flex justify-content-center align-items-center text-center">
          <div>
            {/* <h1><span className="text-big">Manager</span></h1> */}
            <p className="text-big">Create a community</p>
            <p className='gray-text text-medium'>A community serves as a gathering space for members of a cohesive team.</p>
          </div>
        </Col>
      </Row>

      {/* Second Section */}
      <Row className='full-width'>
        <Col sm={6} xs={12} className="d-flex justify-content-center align-items-center text-center">
          <div>
            <p className="text-big">Add your drivers to the team community.</p>
            <p className='gray-text text-medium'>If they accept the invitation, you will have full control on their profiles: access to their bot conversations and view their setups suggestions and their data insertion</p>
          </div>
        </Col>
        <Col sm={6} xs={12} className="d-flex justify-content-center align-items-center">
          <Image className='clipped-image' src={SocialManger} alt="Insert Data" height="355" />
        </Col>
      </Row>

      {/* Third Section */}
      <Row className='full-width'>
        <Col sm={6} xs={12} className="d-flex justify-content-center align-items-center">
          <Image src={control} alt="Analyze Data" height="355" />
        </Col>
        <Col sm={6} xs={12} className="d-flex justify-content-center align-items-center text-center">
          <div>
            <p className="text-big">Keep track of all your drivers setups</p>
            <p className='gray-text text-medium'>View the list of setups of your community drivers past races, divided by date and circuit.</p>
          </div>
        </Col>
      </Row>

      {/* Fourth Section */}
      <Row className='full-width'>
        <Col sm={6} xs={12} className="d-flex justify-content-center align-items-center text-center">
          <div>
            <p className='text-big'>Analyze the team statistics</p>
            <p className='gray-text text-medium'>Guided by our algorithm statistics, gain insights into optimizing your team's performance </p>
          </div>
        </Col>
        <Col sm={6} xs={12} className="d-flex justify-content-center align-items-center">
          <Image className='clipped-image' src={AnalysisManager} alt="Insert Data" height="355" />
        </Col>
      </Row>
    </Container>
  );
}

export default HowItWorksManager;
