import 'react-toastify/dist/ReactToastify.css';

import { Box, Button, Container, TextField, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import HttpProvider from 'services/HttpProvider'
import NewLogo from 'components/NewLogo.png';
import { useLocation } from "react-router-dom";

export default function ActivateAccountDetail() {

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const email = decodeURIComponent(query.get('email'))

  const handleSendEmail = () => {
    const accessToken = localStorage.getItem("access_token");
    return HttpProvider.post(`/send_activation_mail/?email=${email}`, null, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        accept: 'application/json',
      },
    })
      .then((res) => {
        toast.success("Activation email sent successfully!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
        });
        return res
      })
      .catch((error) => {
        toast.error("Failed to send the activation email!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
        return error
      })
  };

  return (
    <Container
      maxWidth={false}
      style={{
        textAlign: "center",
        backgroundColor: "#000",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            p: 4,
            backgroundColor: "#1c1c1e",
            borderRadius: 2,
            maxWidth: 500,
            width: "100%",
            margin: "0 auto"
          }}
        >
          <Box my={2}>
            <Typography variant="h4" className='gradient-text'>Check on your email provider</Typography>
            <Typography component="p" variant="subtitle1" color="white" mt={1}>We've sent you an email with the activation link</Typography>
          </Box>
          <Box textAlign={'center'}>
            <img src={NewLogo} alt="AiMotors logo" width="120px" />
          </Box>
          <Box mt={3}>
            <Typography variant="h5" sx={{ py: 1.5, px: 2, backgroundColor: 'white', mb: 2, borderRadius: 2 }}>{email}</Typography>
            <Button variant="contained" color="secondary" onClick={handleSendEmail} disabled={!email}>
              Send Email Again
            </Button>
          </Box>
        </Box>
      </Box>
      <ToastContainer />
    </Container>
  );
}


