import { Box, Button, Chip, Container, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';

import { Link } from "react-router-dom";
import { styled } from '@mui/system';

const InsertProduct = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (e, index) => {
    setActiveTab(index)
  }

  const BackgroundContainer = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(135deg, #3A49F9 0%, #4D5DFB 100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
    color: '#fff',
    textAlign: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%', // Ensure full width
    margin: 0, // Remove margin
    padding: 0, // Remove padding
  }));
  
  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#fff',
    color: '#3A49F9',
    borderRadius: '25px',
    padding: '10px 20px',
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '1rem',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  }));

  const tabContentLegend = {
    'Competition': {
      img: 'images/COMPETITION.jpg',
      title: 'Create a Competition',
      description: 'Every AiMotor functionality revolves around a specific event, defined by a competition. Each Competition is characterized by a name and is related to a track'
    },
    'Kart Data': {
      img: 'images/insert_home.jpg',
      title: 'Enter Your Kart Data',
      description: 'After a racing session, you can enter the setup you used. Each data entry is divided into three parts: Engine, Chassis, and Driving Experience.'
    },
    'Track and Weather Conditions': {
      img: 'images/manager.jpg',
      title: 'Complete your Insertion',
      description: 'During a karting day, weather and track conditions can change quickly. Whenever this happens, it’s the right moment to use this part of the insertion component to better understand and give context to your previously entered Kart data'
    }
  }
  
  const TabContent = useCallback(({ tab, forceTabInfo = null, ...props }) => {
    const tabMatch = tabContentLegend[tab] || forceTabInfo || null
    if (tabMatch) {
      return (
        <Box
          {...props}
          sx={{
            backgroundColor: 'rgb(34, 34, 34)',
            borderRadius: 2,
            overflow: 'hidden',
            height: { lg: '300px'},
            ...(props.sx || {})
          }}
          
        >
          <Grid container alignItems={'center'} sx={{ height: '100%' }}>
            <Grid item md={6}>
              <img src={tabMatch.img} alt={tabMatch.title} className='img-fluid' />
            </Grid>
            <Grid item md={6}>
              <Stack spacing={1} py={4} px={{ xs: 2, md: 6 }} alignItems={'start'} justifyContent={'center'} color='#fff'>
                <Typography fontSize={'24px'} fontWeight={700}>{tabMatch.title}</Typography>
                <Typography fontSize={'16px'}>{tabMatch.description}</Typography>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )
    }
    return null
  }, []);

  const dashboardStyles = {
    backgroundColor: 'black',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  };

  const tabsLegend = ['Competition', 'Kart Data', 'Track and Weather Conditions']

  return (
    <Box backgroundColor="#000" pt={"100px"}>
      <Container maxWidth={false} className='container-lg'>
        <Stack spacing={{ xs: '80px', lg: '100px' }}>
          <Stack textAlign={'center'} spacing={1}>
            <Typography variant='h4' color="white" fontWeight={700}>
              <span className="gradient-title">AiMotor: Powered by Data.</span>
            </Typography>
            <Typography variant='p' color="white">
The data you enter will be usable across the entire app
            </Typography>
          </Stack>

          <Box>
          <Stack direction="row" spacing={1} mb={5} justifyContent={'center'}>
        <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" color='primary' clickable /*sx={{color: 'white' }}*/ />
        <Chip label="For Racing Teams" component="a" href='#For Team Managers' variant="outlined" clickable sx={{ color: 'white' }} />
      </Stack>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#fff"
              }
            }}
            indicatorColor="white"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabsLegend.map((tab, index) => (
              <Tab
                key={`tab-${index}`}
                label={tab}
                value={index}
                sx={{
                  color: '#fff',
                  "&.Mui-selected": {
                    color: '#fff',
                  }
                }}
              />
            ))}
            </Tabs>
            {tabsLegend.map((tab, index) => (
              <Box hidden={activeTab !== index} mt={2} key={`content-${index}`}>
                <TabContent tab={tab} />
              </Box>
            ))}
          </Box>
          <Box>
            <TabContent
              forceTabInfo={{
                img: 'videos/autocompilation.gif',
                title: 'Autocompilation',
                description: 'Autocompilation ensures smooth and rapid data entry: each field will be pre-filled with the racing data from the previous session, so you only need to update the fields that have changed from one session to the next'
              }}
              sx={{
                height: 'inherit'
              }}
            />
          </Box>
          <Stack direction="row" spacing={1} sx={{ flexDirection: "row", justifyContent: "center"}}>
        <Chip label="For Personal Space" id="For Riders" component="a" href="#For Riders" variant="outlined" clickable sx={{ color: 'white' }} />
        <Chip label="For Racing Teams" id="For Team Managers" component="a" href='#For Team Managers' variant="outlined" color='primary' clickable />
      </Stack>
          <div style={dashboardStyles}>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              sx={{
                fontSize: '2rem',
                fontWeight: 'bold',
       
              }}
            >
              Teams go faster with AiMotor
            </Typography>

            <Typography
              variant="body1"
              component="div"
              gutterBottom
              sx={{
                fontSize: '1rem',
              }}
            >
            </Typography>
            <Grid container spacing={4} justifyContent="center" sx={{ marginTop: '20px' }}>
              <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                <Box sx={{ textAlign: 'center', padding: '20px', backgroundColor: '#333', borderRadius: '10px', marginBottom: '35px', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#fff' }}>Allow Others to Enter Data for You</Typography>
                  <Typography variant="body1" sx={{ color: '#fff' }}>You don’t need to enter your data directly; the team manager can do it for you</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} sx={{ display: 'flex' }}>
                <Box sx={{ textAlign: 'center', padding: '20px', backgroundColor: '#333', borderRadius: '10px', marginBottom: '35px', flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#fff' }}>Multiple Insertion</Typography>
                  <Typography variant="body1" sx={{ color: '#fff' }}>By selecting multiple drivers in the Insertion component, a lot of data can be entered with just one click</Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
      <Box mt={10}>
        <BackgroundContainer>
          <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
            Go Faster today
          </Typography>
          <Link to="/sign-up" className="insertdata">
            <StyledButton variant="contained">
              Start Now
            </StyledButton>
          </Link>
        </BackgroundContainer>
      </Box>
    </Box>
  );
};

export default InsertProduct;
