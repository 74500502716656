import "../components.css";
import Mission from "../Mission";
import HeroSection from "../HeroSection";
import HowItWorksDriver from "../HowItWorksDriver";

import { Container } from "react-bootstrap";
import Footer from "../Footer";
import LearnForm from "../LearnForm";
import HowItWorksManager from "../HowItWorksManager";
import Infinitescroll from "../Infinitescroll";
import AiUses from "../AiUses";
import SecondPartHome from "../SecondPartHome";
import HomeStepper from "../HomeStepper";
import CallToAction from "./CallToAction";
function Home() {
  return (
    <>
      <Container fluid className="homepage">
        <HeroSection />

      </Container>


<Infinitescroll/>

      <SecondPartHome/>
      <callToAction/>

      {/* <Mission />
      <LearnForm />
      <AiUses /> */}
      {/* <HowItWorksDriver />
      <HowItWorksManager /> */}
      {/* <CallToAction /> */}


      <HomeStepper/>
      <CallToAction/>
      <Footer />
    </>
  );
  // }
}
export default Home;
