import React, { useEffect, useState, useRef } from "react";

const itemsRow1 = [
  "I am experiencing understeer. Should I increase the camber angle to correct it",
  "Can you help me understand why my teammate is achieving faster lap times than I am",
  "I'm unsure if changing the sprocket is the right decision for qualifying. Considering my racing data from the previous day, what do you suggest",
  "In my last sessions, the kart felt heavy to drive. Considering my data and my teammates' data, what changes do you suggest to overcome this issue?",
  "What changes have I made from one session to the other?",
];

const itemsRow2 = [
  "Can you tell me who the fastest driver on my team was for each session yesterday and what setups they were using?",
  "Do you think that changing the axle position for the next session is the right choice",
  "What setup do you suggest using for my next session?",
  "Should I increase the caster for my next session?",
  "Theoretically, what does increasing the rear tire pressure imply for my chassis?",
];

const itemsRow3 = [
  "What caster did my fastest teammate use in yesterday's first session",
  "I want to change the axle, but I don't know how to do it",
  "In my last sessions, I had difficulties making my kart turn, and I was missing rear grip in the middle of the corner. Considering my data and my teammates' data, and knowing that the next session will be 25°C and I will have new tires, what changes do you suggest?",
  "Who was the fastest teammate in yesterday's second session and what sprocket was he using?",
  "With which sprocket and pinion did I achieve my maximum RPM yesterday?",
];

const Infinitescroll = () => {
  const [isPaused1, setIsPaused1] = useState(false);
  const [isPaused2, setIsPaused2] = useState(false);
  const [isPaused3, setIsPaused3] = useState(false);

  const current1 = useRef(0);
  const current2 = useRef(0);
  const current3 = useRef(0);

  useEffect(() => {
    const scrollRow = (rowId, currentRef, isPaused, direction) => {
      if (!isPaused) {
        const rowElement = document.getElementById(rowId);
        if (rowElement) {
          currentRef.current += direction;
          const rowWidth = rowElement.scrollWidth;
          const containerWidth = rowElement.parentElement.offsetWidth;

          if (direction === -1 && currentRef.current <= -rowWidth / 3) {
            currentRef.current = 0;
          } else if (direction === 1 && currentRef.current >= 0) {
            currentRef.current = -rowWidth / 3;
          }

          rowElement.style.transform = `translateX(${currentRef.current}px)`;
        }
      }
    };

    const interval1 = setInterval(() => {
      scrollRow("row-1", current1, isPaused1, -1);
    }, 20);

    const interval2 = setInterval(() => {
      scrollRow("row-2", current2, isPaused2, 1);
    }, 20);

    const interval3 = setInterval(() => {
      scrollRow("row-3", current3, isPaused3, -1);
    }, 20);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, [isPaused1, isPaused2, isPaused3]);

  const handleMouseEnter = (row) => {
    console.log(`Mouse enter on row ${row}`);
    if (row === 1) setIsPaused1(true);
    if (row === 2) setIsPaused2(true);
    if (row === 3) setIsPaused3(true);
  };

  const handleMouseLeave = (row) => {
    console.log(`Mouse leave on row ${row}`);
    if (row === 1) setIsPaused1(false);
    if (row === 2) setIsPaused2(false);
    if (row === 3) setIsPaused3(false);
  };

  return (
    <div style={styles.container}>
      <div id="row-1" style={styles.row}>
        {itemsRow1.concat(itemsRow1, itemsRow1).map((item, index) => (
          <div
            key={index}
            style={styles.box}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={() => handleMouseLeave(1)}
          >
            {item}
          </div>
        ))}
      </div>
      <div id="row-2" style={styles.row}>
        {itemsRow2.concat(itemsRow2, itemsRow2).map((item, index) => (
          <div
            key={index}
            style={styles.box}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={() => handleMouseLeave(2)}
          >
            {item}
          </div>
        ))}
      </div>
      <div id="row-3" style={styles.row}>
        {itemsRow3.concat(itemsRow3, itemsRow3).map((item, index) => (
          <div
            key={index}
            style={styles.box}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={() => handleMouseLeave(3)}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    overflow: "hidden",
    padding: "25px 5px",
    backgroundColor: "black",
  },
  row: {
    display: "flex",
    whiteSpace: "nowrap",
  },
  box: {
    display: "inline-block",
    margin: "0 10px",
    marginTop: "10px",
    padding: "25px",
    backgroundColor: "#222",
    borderRadius: "4px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    color: "white",
    cursor: "pointer",
  },
};

export default Infinitescroll;
