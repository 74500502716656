import { Col, Container, Form, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import EditableCell from "./EditableCell";
import EditableCellNumeric from "./EditableCellNumeric";
import EditableCellSelect from "./EditableCellSelect";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";

const SessionsByDateAndGroup = ({ raceWeekend }) => {
  const [sessionData, setSessionData] = useState([]);
  const [driver, setDriver] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [day, setDay] = useState("");
  const [updateValue, setUpdateValue] = useState("");
  const [selectedXAxisField, setSelectedXAxisField] = useState("");
  const [fields, setFields] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [selectedComponent, setSelectedComponent] = useState("RaceForm");
  const [competitions, setCompetitions] = useState([]);
  const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionResponse = await axios.get(
          `https://aimotor.eu/api/get_total_race_data/?`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        setSessionData(sessionResponse.data);
        
        const driversResponse = await axios.get(
          `https://aimotor.eu/api/get_drivers/?team_id=${selectedTeam.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              accept: "application/json",
            },
          }
        );
        setDrivers(driversResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("Fetching competitions...");
    axios
      .get(`https://aimotor.eu/api/get_competitions/?team_id=${selectedTeam.id}`)
      .then((res) => {
        const competitions = res.data;
        console.log("Competitions data received:", competitions);
        setCompetitions(competitions);
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "driver") {
      setDriver(value);
    } else if (name === "day") {
      setDay(value);
    } else if (name === "xAxisField") {
      setSelectedXAxisField(value);
    }
  };

  const handleSelect = (event) => {
    setSelectedComponent(event.target.value);
  };

  const filterSessions = () => {
    return sessionData.filter((session) => {
      return (
        (driver === "" || session.full_name === driver) &&
        (day === "" || session.day === parseInt(day)) &&
        (raceWeekend === "" || session.race_weekend === raceWeekend)
      );
    });
  };

  const renderTables = () => {
    const groupedSessions = filterSessions();

    groupedSessions.sort((a, b) => {
      if (parseInt(a.session_group) !== parseInt(b.session_group)) {
        return parseInt(a.session_group) - parseInt(b.session_group);
      } else if (parseInt(a.session_number) !== parseInt(b.session_number)) {
        return parseInt(a.session_number) - parseInt(b.session_number);
      }

      return a.chassis_number.localeCompare(b.chassis_number);
    });
  
 

    return groupedSessions.map((session, index) => {
      return (
        <Container key={index}>
          <div>
            <h2>
              Session Number {session.session_number} and Group{" "}
              {mapGroup(session.session_group)}
            </h2>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
          
                <th>Lap Time</th>
                <th>Chassis Number</th>
                <th>Engine Number</th>
                <th>Tires New</th>
                <th>Carburetor</th>
                <th>Hot Pressure</th>
                <th>Cold Pressure</th>
                <th>Max Exhaust</th>
                <th>Min Exhaust</th>
                <th>Sprocket</th>
                <th>Pinion</th>
                <th>Asphalt Temperature</th>
                <th>Max Speed</th>
                <th>Max RPM</th>
                <th>Min RPM</th>
                <th>Caster</th>
                <th>Camber</th>
                <th>Axle Type</th>
                <th>Axle Position</th>
                </tr>
              </thead>
              <tbody>
                
                <tr>
              
                 
              
                  <td>{session.laptime}</td>
                  <td>{session.chassis_number}</td>
                  <td>{session.engine_number}</td>
                  <td>{mapperYN(session.tires_new)}</td>
                    <td>{mapCarburetor(session.carburetor)}</td>
                    <td>{session.pressure_hot}</td>
                    <td>{session.pressure_cold}</td>
                    <td>{session.max_exhaust}</td>
                    <td>{session.min_exhaust}</td>
                    <td>{session.crown}</td>
                    <td>{session.pinion}</td>
                    <td>{session.temperature}</td>
                    <td>{session.max_speed}</td>
                    <td>{session.max_rpm}</td>
                    <td>{session.min_rpm}</td>
                    <td>{session.caster}</td>
                    <td>{session.camber}</td>
                    <td>{session.axle_type}</td>
                    <td>{mapHeight(session.axle_position)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Container>
      );
    });
  };


  const mapHeight = (value) => {
    switch (value) {
      case 0:
        return "Low";
      case 1:
        return "Medium";
      case 2:
        return "High";
      default:
        return "Unknown";
    }
  };

  const mapCategory = (value) => {
    switch (value) {
      case 0:
        return "Mini";
      case 1:
        return "Junior";
      case 2:
        return "Senior";
      case 3:
        return "Shifter";
      default:
        return "Unknown";
    }
  };

  const mapGroup = (value) => {
    switch (parseInt(value)) {
      case 1:
        return "1";
      case 2:
        return "2";
      case 3:
        return "3";
      case 4:
        return "4";
      case 5:
        return "A";
      case 6:
        return "B";
      case 7:
        return "C";
      case 8:
          return "D";
      case 9:
          return "E";
      case 10:
          return "F";
      case 11:
          return "G";
      case 12:
          return "H";

      default:
        return "Unknown";
    }
  }
  const mapperYN = (value) => {
    return value === "1" ? "Yes" : "No";
  };

  const mapCarburetor = (value) => {
    switch (parseInt(value)) {
      case 1:
        return "Tilltson (Junior)";
      case 2:
        return "Ibea F3";
      case 3:
        return "Ibea F6";
      case 4:
        return "TM";
      case 5:
        return "Tilltson New (Senior)";
      case 6:
        return "Tilltson Old (Senior)";
      default:
        return "Unknown";
   }
  };
  const [showGraph, setshowGraph] = useState(false);
  const handleClick = () => {
    setshowGraph(true);
  };
  

  // Define mapGroup function and other helper functions here

  return (
    <div>
      <Container>
      <Col xs={12} className="mb-3">
            <h3>
              Analysis
            </h3>
          </Col>
      <Row>
    
    
    <Col xs={12} sm={6} md={4}>
    <Form.Group className="mb-3">
      <Form.Label>Select Driver:</Form.Label>
      <Form.Select
        name="driver"
        value={driver}
        onChange={handleChange}
        required
      >
        <option value="">Select Driver</option>
        {drivers.map((driver) => (
          <option key={driver.id} value={driver.id}>
            {driver.full_name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  </Col>

    <Col xs={12} sm={6} md={4}>
    <Form.Group className="mb-3">
      <Form.Label>Select The Day:</Form.Label>
      <Form.Select
        name="day"
        value={day}
        onChange={handleChange}
        required
      >
        <option value="">Racing Day</option>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
       
      </Form.Select>
    </Form.Group>
    </Col>


      </Row>
    <Row>
    {renderTables()}

    <Row>
    {/* <Col xs={12} className="mb-3">
            <h3>
              Graphs
            </h3>
          </Col> */}
        </Row>
    </Row>

      </Container>
    </div>
  );
};

export default SessionsByDateAndGroup;



