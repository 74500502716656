import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./components.css";
import { Container, Row, Col, Table, Modal, Button } from "react-bootstrap";
import axios from "axios";
import Form from "react-bootstrap/Form";
import dayjs from "dayjs";
import { IoMdCreate } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import EditableCell from "./EditableCell";
import EditableCellSelect from "./EditableCellSelect";
import EditableCellNumeric from "./EditableCellNumeric";
import { MdDelete } from "react-icons/md";

const RemoteForm = () => {
  const calculateStatistics = (fieldName, filteredData) => {
    if (!filteredData || filteredData.length === 0) {
      return {
        average: 0,
        max: 0,
        min: 0,
      };
    }

    const total = filteredData.reduce(
      (acc, entry) => acc + parseFloat(entry[fieldName]),
      0
    );
    const average = (total / filteredData.length).toFixed(2);

    // Calculate maximum and minimum values
    let maxValue = -Infinity;
    let minValue = Infinity;
    filteredData.forEach((entry) => {
      const value = parseFloat(entry[fieldName]);
      if (value > maxValue) {
        maxValue = value;
      }
      if (value < minValue) {
        minValue = value;
      }
    });

    let averageLabel;
    if (fieldName === "axle_type") {
      averageLabel = mapHardness(average);
    } else if (fieldName === "axle_position") {
      averageLabel = mapHeight(average);
    } else {
      averageLabel = average;
    }

    return {
      average: averageLabel,
      max: maxValue.toFixed(2),
      min: minValue.toFixed(2),
    };
  };

  const mapperYN = [
    { value: "0", label: "No" },
    { value: "1", label: "Yes" },
  ];
  const history = useHistory();
  const [racingData, setRacingData] = useState([]);
  const [driver, setDriver] = useState("All");
  const [drivers, setDrivers] = useState(["All"]);
  const [track, setTrack] = useState("All");
  const [tracks, setTracks] = useState(["All"]);
  const [sessionType, setSessionType] = useState("All");
  const [sessionTypes, setSessionTypes] = useState(["All"]);
  const [category, setCategory] = useState("All");
  const [sessionNumber, setSessionNumber] = useState("All");
  const [sessionGroup, setSessionGroup] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [id, setId] = useState(-1);
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("access_token");

  useEffect(() => {
    // Check if the user is logged in (e.g., by checking the presence of the access token in local storage
    const params = JSON.parse(localStorage.getItem("user_data"));
    const apiUrl = `https://aimotor.eu/api/get_race_weekend_driver_data/`;
    // const apiUrl = `http://127.0.0.1:8000/get_race_data/?email=${params.email}&role=${params.role}&team=${params.team}`;
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        if (data !== null) {
          setRacingData(data);
        }
      })
      .catch((error) => {
        console.error("Query Error:", error);
      });
  }, [show]);

  useEffect(() => {
    setTracks(["All", ...new Set(racingData.map((data) => data.track))]);
    setSessionTypes([
      "All",
      ...new Set(racingData.map((data) => data.session_type)),
    ]);
    setDrivers(["All", ...new Set(racingData.map((data) => data.full_name))]);
  }, [racingData]);

  useEffect(() => {
    filterData();
  }, [
    racingData,
    driver,
    track,
    sessionType,
    category,
    sessionNumber,
    sessionGroup,
  ]);

  const filterData = () => {
    // Apply filters based on track, driver, sessionType, category, sessionNumber, and selectedSessionGroups
    if (racingData) {
      let startData = racingData.slice();
      if (track !== "All") {
        startData = startData.filter((data) => data.track === track);
      }

      if (driver !== "All") {
        startData = startData.filter((data) => data.full_name === driver);
      }

      if (sessionType !== "All") {
        startData = startData.filter(
          (data) => data.session_type === sessionType
        );
      }

      if (category !== "All") {
        startData = startData.filter((data) => data.category === category);
      }

      if (sessionNumber !== "All") {
        startData = startData.filter(
          (data) => data.session_number === sessionNumber
        );
      }

      if (sessionGroup.length > 0) {
        startData = startData.filter((data) =>
          sessionGroup.includes(data.session_group)
        );
      }

      setFilteredData(startData);
    }
  };

  const mapHeight = (value) => {
    switch (value) {
      case 0:
        return "Low";
      case 1:
        return "Medium";
      case 2:
        return "High";
      default:
        return "Unknown";
    }
  };

  const mapTiming = (value) => {
    switch (parseInt(value)) {
      case 0:
        return "Too early";
      case 1:
        return "Normal";
      case 2:
        return "Too late";
      default:
        return "Unknown";
    }
  };

  const mapHardness = (value) => {
    switch (parseInt(value)) {
      case 0:
        return "Soft";
      case 1:
        return "Medium";
      case 2:
        return "Hard";
      case 3:
        return "Rain";
      default:
        return "Unknown";
    }
  };

  const mapCarburetor = (value) => {
    switch (parseInt(value)) {
      case 1:
        return "Tilltson (Junior)";
      case 2:
        return "Ibea F3";
      case 3:
        return "Ibea F6";
      case 4:
        return "TM";
      case 5:
        return "Tilltson New (Senior)";
      case 6:
        return "Tilltson Old (Senior)";

      default:
        return "Unknown";
    }
  };

  const updateValue = (value, field, id) => {
    // query to update
    axios
      .post(
        `https://aimotor.eu/api/change_data/?value=${value}&field=${field}&id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        const userData = userResponse.data;
      })
      .catch((error) => {
        toast.error("Failed to update the value!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const deleteValue = (id) => {
    // query to update
    axios
      .post(
        `https://aimotor.eu/api/delete_data/?id=${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`, // Include the access token in the header
            accept: "application/json",
          },
        }
      )
      .then((userResponse) => {
        toast.success("Entry deleted succesfully!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        handleClose();
        // window.location.reload();
      })
      .catch((error) => {
        toast.error("Failed to delete the entry!", {
          position: "top-right",
          autoClose: 2000, // Close after 3 seconds
          hideProgressBar: false,
        });
        console.error("Query Error:", error);
      });
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };

  const sessionGroupOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "A" },
    { value: "6", label: "B" },
    { value: "7", label: "C" },
    { value: "8", label: "D" },
    { value: "9", label: "E" },
    { value: "10", label: "F" },
    { value: "11", label: "G" },
    { value: "12", label: "H" },
  ];

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 9999, // Set a high z-index value to ensure it appears above other elements
    }),
  };

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this entry?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => deleteValue(id)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Form>
        <Row>
          <Col md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="categorySelect">
                Select Category:{" "}
              </Form.Label>
              <Form.Select
                id="categorySelect"
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              >
                <option value="">Category</option>
                <option value="MINI">Mini</option>
                <option value="JUNIOR">Junior</option>
                <option value="SENIOR">Senior</option>
                <option value="SHIFTER">Shifter</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="sessionNumberSelect">
                Select Session Number:{" "}
              </Form.Label>
              <Form.Select
                id="sessionNumberSelect"
                value={sessionNumber}
                onChange={(event) => setSessionNumber(event.target.value)}
              >
                <option value="">Session Number</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6} lg={4}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="sessionGroupSelect">
                Select Session Group:{" "}
              </Form.Label>
              <Form.Select
                id="sessionGroupSelect"
                options={sessionGroupOptions}
                isMulti
                value={sessionGroupOptions.filter((option) =>
                  sessionGroup.includes(option.value)
                )}
                onChange={(selectedOptions) =>
                  setSessionGroup(selectedOptions.map((option) => option.value))
                }
                styles={customStyles} // Apply custom styles to the Select component
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {filteredData.length > 0 ? (
        <Table responsive striped bordered hover className=" custom-table">
          <thead>
            <tr>
              <th></th>
              <th>Date</th>
              <th>Driver</th>
              <th>Lap Time</th>
              <th>Tires New</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((entry, index) => (
              <tr key={index}>
                <td style={{ verticalAlign: "middle" }}>
                  <MdDelete
                    size="1.5rem"
                    onClick={() => handleShow(entry.id)}
                  />
                </td>
                <EditableCell
                  value={dayjs(entry.timestamp_location).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                  onSave={updateValue}
                  field="timestamp"
                  id={entry.id}
                />
                <td>{entry.full_name}</td>
                <EditableCell
                  value={entry.laptime}
                  onSave={updateValue}
                  field="laptime"
                  id={entry.id}
                />

                <EditableCellSelect
                  value={entry.tires_new}
                  onSave={updateValue}
                  field="tires_new"
                  id={entry.id}
                  vals={mapperYN}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No data to display.</p>
      )}

      {/* <Container>
  <Row>
    <Col xs={12} sm={6} md={4}>
      <h1>Statistics</h1>
      <Table bordered>
      {filteredData.length > 0 && (
        <thead>
          <tr>
            <th className="header">FIELD</th>
            <th className="header">AVERAGE</th>
            <th className="header">MAXIMUM</th>
            <th className="header">MINIMUM</th>
          </tr>
        </thead>
        )}
        <tbody>
        {filteredData.length > 0 && (
          <tr>
            <td>lapTime</td>
            <td>{isNaN(calculateStatistics('laptime',filteredData).average) ? 0 : calculateStatistics('laptime',filteredData).average}</td>
            <td>{calculateStatistics('laptime',filteredData).max}</td>
            <td>{calculateStatistics('laptime',filteredData).min}</td>
          </tr>
          )}
        </tbody>
      </Table>
    </Col>
  </Row>
</Container> */}
    </Container>
  );
};

export default RemoteForm;
