import '../components.css';

import * as React from 'react';

import { Checkbox, FormControlLabel, useMediaQuery, useTheme } from '@mui/material';
import { Link, NavLink, Route, Switch, useHistory, } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useEffect, useState } from "react";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import { FaCopy } from 'react-icons/fa'; // Importa l'icona di copia
import FormControl from '@mui/material/FormControl';
import { Grid } from '@mui/material';
import { Height } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Pricing from "./Pricing.js";
import SettingsIcon from '@mui/icons-material/Settings';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import axios from "axios";
import dayjs from "dayjs";
import myUserStore from 'stores/UserStore';
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import zIndex from '@mui/material/styles/zIndex';

function PageHeader() {
  const [drivers, setDrivers] = useState([]);
  const [team, setTeam] = useState({});
  const [driverRoles, setDriverRoles] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [addMember, SetAddMember] = useState(false);
  const [addEmail, SetAddEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [wantToDelete, setWantToDelete] = useState(false);
  const [wantToAbandon, setWantToAbandon] = useState(false);
  const [wantToRemove, setWantToRemove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDriver, setIsLoadingDriver] = useState(false);
  const [openPrice, setOpenPrice] = useState(false);
  const [showChangeName, setShowChangeName] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [changedRole, setChangedRole] = useState("");
  const [showRemoveMembers, setShowRemoveMembers] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const history = useHistory();
  const [showPricing, setShowPricing] = useState(false);
  const parsedUserData = JSON.parse(localStorage.getItem("user_data"));

  // const myPlan = myUserStore.planCode || "normal";
  const myPlan = "Team"
  console.log("my plan is", myPlan);

  const transformData = (data) => {
    const { email, surname, teams, owner, teams_info } = data;
    const selectedTeam = teams_info.find(team => team.team_id === parseInt(teamId));
    const theTeam = teams.find(team => team.team_id === parseInt(teamId));
    const owner_id = theTeam.owner_id;
    let transformedTeamsInfo = {};
    if (selectedTeam) {
      transformedTeamsInfo = {
        id: selectedTeam.team_id,
        name: selectedTeam.team_name,
        role: selectedTeam.role,
        owner_id: owner_id,
        isOwner: owner.some(t => t.team_id === selectedTeam.team_id && t.team_owner === parsedUserData.user_id),
        members: selectedTeam.members,
      };
    }

    return transformedTeamsInfo;
  };

  const getSettings = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        `https://aimotor.eu/api/get_settings`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      );

      console.log("Team info before", res.data);
      setTeam(transformData(res.data));
      setTeamName(transformData(res.data).name);
      setIsLoading(false);
      console.log("Team info", transformData(res.data));
      console.log("user data", parsedUserData);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  console.log(team);

  const isManager = React.useMemo(() => {
    return team.role === "MGR";
  }, [,team?.role ]);

  const handleChipClick = (chip) => {
    setSelectedRole(chip);
  };

  const { teamId } = useParams();

  useEffect(() => {
    axios
      .get(`https://aimotor.eu/api/get_drivers/?team_id=${parseInt(teamId)}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          accept: "application/json",
        },
      })
      .then((res) => {
        setIsLoadingDriver(true);
        console.log("Drivers API Response:", res.data);
        setDrivers(res.data);
        const initialRoles = {};
        res.data.forEach(driver => {
          initialRoles[driver.full_name] = '';
        });
        setDriverRoles(initialRoles);
        console.log("Driver roles", initialRoles);
        setIsLoadingDriver(false);
      })
      .catch((error) => {
        setIsLoadingDriver(false);
        console.error("Axios Error:", error);
      });
  }, []);

  console.log("user data",);
  const handleWantToDelete = () => {
    setWantToDelete(!wantToDelete);
  };
  const handleWantToRemove = () => {
    setWantToRemove(!wantToRemove);
  };
  const handleWantToAbandon = () => {
    setWantToAbandon(!wantToAbandon);
  };

  const handlePricingClose = () => {
    setOpenPrice(!openPrice);
  };

  const changeTeamName = async () => {
    try {
      const apiUrl = `https://aimotor.eu/api/change_team_name/?role=MGR&team_id=${parseInt(teamId)}&new_team_name=${teamName}`;
      const res = await axios.post(
        apiUrl,
        {
          team_id: parseInt(teamId),
          new_team_name: teamName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then((response) => {
          toast.success("Team name changed!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
          });
          setShowChangeName(false);
          window.location.reload();
        });
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const handleEditTeamName = () => {
    setShowChangeName(!showChangeName);
  };

  const handleInviteClick = async () => {
    try {
      const apiUrl = `https://aimotor.eu/api/invite_member/?role=DRV&team_id=${parseInt(teamId)}&status=PENDING&user_email=${addEmail}`;
      const res = await axios.post(
        apiUrl,
        {
          role: selectedRole,
          team_id: parseInt(teamId),
          status: "PENDING",
          user_email: addEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then((response) => {
          toast.success("Member invited!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
          });
          SetAddMember(false);
        })
        .catch((error) => {
          if (error.response && error.response.data.detail === "Member already in the Team") {
            toast.error("Member already invited", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
            });
          } else {
            console.error("Error ", error);
          }
        });
    } catch (error) {
      console.error("Error ", error);
    }
  };

  const handleDeleteTeamClick = async () => {
    try {
      const apiUrl = `https://aimotor.eu/api/delete_team/?team_id=${parseInt(teamId)}`;
      const res = await axios.post(
        apiUrl,
        {
          team_id: parseInt(teamId),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then((response) => {
          toast.success("Team deleted !", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
          });
          history.push(`/racing-space/settings`);
          setWantToDelete(!wantToDelete);
        });
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleChangeRoleClick = async (user_id, new_role) => {
    try {
      const apiUrl = `https://aimotor.eu/api/change_role/?team_id=${parseInt(teamId)}&new_role=${new_role}&user_id=${user_id}`;
      const res = await axios.post(
        apiUrl,
        {
          team_id: parseInt(teamId),
          new_role: new_role,
          user_id: user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then((response) => {
          toast.success("Role changed!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
          });
          setChangedRole(new_role);
        });
    } catch (error) {
      console.error("Error while changing the role!", error);
    }
  };



  const handleDeleteMemberClick = async (id) => {
    try {
      const apiUrl = `https://aimotor.eu/api/delete_member/?user_id=${id}&team_id=${parseInt(teamId)}`;
      const res = await axios.post(
        apiUrl,
        {
          user_id: id,
          team_id: parseInt(teamId),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            accept: "application/json",
          },
        }
      )
        .then((response) => {
          
          setDrivers(drivers.filter(driver => driver.user_id !== id));
          setWantToRemove(!wantToRemove);
        });
    } catch (error) {
      console.error("Error", error);
    }
  };

 

  const handleChange = (event, fullName, userId) => {
    const selectedRole = event.target.value;
    setDriverRoles(prevState => ({
      ...prevState,
      [fullName]: selectedRole,
    }));
    handleChangeRoleClick(userId, selectedRole);
  };

  const handleAddMemberClick = () => {
    if (!fullPro) {
      SetAddMember(!addMember);
    } else {
      setShowPricing(true);
    }
  };

  const handleCloseAddMember = () => {
    SetAddMember(!addMember);
  };

  const toggleShowAll = () => {
    if(showAll){
      setShowRemoveMembers(false)
    }
    setShowAll(!showAll);
  };

  const toggleRemoveMembers = () => {
    setShowRemoveMembers(!showRemoveMembers);
    setSelectedMembers([]); // Reset selected members
  };

  const handleMemberSelection = (id) => {
    setSelectedMembers(prevSelected =>
      prevSelected.includes(id)
        ? prevSelected.filter(memberId => memberId !== id)
        : [...prevSelected, id]
    );
   
  };

  const confirmRemoveMembers = async () => {
    await removeIt(selectedMembers);
    setShowRemoveMembers(false);
  };

  const removeIt = async (members) => {
    try {
      const promises = members.map(id => handleDeleteMemberClick(id));
      await Promise.all(promises);
      
    } catch (error) {
      console.error("Error removing members: ", error);
      toast.error("Error removing members", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
      });
    }
  };

  const generateUrl = React.useMemo(() => {
    return `${document.location.origin}/invited-member?teamId=${teamId}`;
  }, [teamId]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generateUrl).then(() => {
      alert('Testo copiato negli appunti!');
    }).catch(err => {
      console.error('Errore nella copia del testo: ', err);
    });
  };

  const handleAddEmail = (event) => {
    SetAddEmail(event.target.value);
  };

  const MyFullname = `${parsedUserData.name} ${parsedUserData.surname}`;
  console.log("My fullname", MyFullname);

  const handleSettingsClick = () => {
    history.push(`/racing-space/settings`);
  };

  const handleNameChange = (event) => {
    setTeamName(event.target.value);
  };

  const style = {
    cursor: 'pointer',
    marginRight: '10px',
    height: isMobile ? '30px' : '22px',
    width: isMobile ? '30px' : '23px',
    color: 'black',
    zIndex: 20000,
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 440);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const fullPro = myPlan == "Pro" && team?.members?.length > 5;
  console.log("Is full Pro?", fullPro);
  useEffect(() => {
    getSettings();
  }, [, changedRole, wantToRemove]);

  return (
    <>
      <Pricing open={showPricing} onClose={() => setShowPricing(false)} />
      {addMember && (
        <div className="elements-bars" data-palette-mode="dark">
          <div className="frame">
            <p1 className="p">
              COPY INVITATION LINK
            </p1>
            <Chip
              sx={{
                color: "black",
                backgroundColor: "#2f485b",
                display: "flex",
                '@media (max-width: 440px)': { width: "251px" },
              }}
              label={
                <Box onClick={copyToClipboard} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', width: '100%' }}>
                  <FaCopy
                    onClick={copyToClipboard}
                    style={style}
                    title="Copia negli appunti"
                    size={20} // Adjust size if necessary
                  />
                  <Box
                    component="span"
                    sx={{
                      marginLeft: '8px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      flexGrow: 1,
                    }}
                  >
                    {generateUrl}
                  </Box>
                </Box>
              }
            />
            <div className="div">
              <p className="p">
                INVITE BY EMAIL
              </p>
              <div className="div">
                <div className="frame-2">
                  <div className="text-field" data-palette-mode="dark">
                    <div className="content">
                      <div className="input">
                        <div className="content-2">
                          <div className="min-height" />
                          <TextField
                            onChange={handleAddEmail}
                            type="email"
                            value={addEmail}
                            className="text-field-instance"
                            InputLabelProps={{
                              style: { color: '#ffffff' },
                            }}
                            label="EMAIL"
                            variant="filled"
                            size="medium"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="select">
                    <div className="content-3">
                      <div className="input-2">
                        <Button
                          color="secondary"
                          size="medium"
                          variant="contained"
                          onClick={handleInviteClick}
                        >INVITE</Button>
                      </div>
                    </div>
                  </div>
                  <div className="select">
                    <div className="content-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CloseIcon
            className="design-component-instance-node"
            sx={{ color: 'white', cursor: 'pointer' }}
            onClick={handleCloseAddMember}
            size="medium"
          />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: 20, position: 'relative',}}>
        <Grid container direction="row" spacing={1} justifyContent="space-between" alignContent="center">
          <Grid item>
            {isLoading && (<Skeleton variant="text" sx={{ fontSize: '2rem' }} width={200} />)}
            {!isLoading && (<Typography sx={{ fontSize: 33, width: '100%' }} className="text-wrapper">{team.name}</Typography>)}
          </Grid>
          <Grid item justifyContent="flex-end" sx={{ display: 'flex', alignItems: 'center' }}>
            {!addMember && isManager && (
              <>
                <Button variant="outlined" onClick={handleSettingsClick} startIcon={<ArrowBackIcon />} sx={{ marginRight: 3 }} >
                  Go Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={handleAddMemberClick}
                >
                  ADD MEMBER
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 16, marginBottom: 14 }}>
          <Typography sx={{ fontSize: 24, color: 'gray'}}>TEAM MEMBERS</Typography>
          <div>
            {(team?.members?.length > 6) && <Button onClick={toggleShowAll}>{showAll ? 'Show Less' : 'Show All'}</Button>}
            {isManager && (showAll||(team?.members?.length < 6)) && <Button onClick={toggleRemoveMembers} color='error'>{showRemoveMembers ? 'Cancel' : 'Remove Members'}</Button>}
          </div>
        </div>
        <div style={{display:  'grid', gridTemplateColumns:'repeat(auto-fit, minmax(200px, 1fr))',justifyItems: 'flex-start', gap: '20px' }}>
          {isLoading && (
            <div>
              <Skeleton variant="rectangular" sx={{ maxWidth: 200, borderRadius: '8px', marginBlock: 2 }} height={50} />
              <Skeleton variant="rectangular" sx={{ maxWidth: 200, borderRadius: '8px', marginBlock: 2 }} height={50} />
              <Skeleton variant="rectangular" sx={{ maxWidth: 200, borderRadius: '8px', marginBlock: 2 }} height={50} />
              <Skeleton variant="rectangular" sx={{ maxWidth: 200, borderRadius: '8px', marginBlock: 2 }} height={50} />
            </div>
          )}
          {!isLoading && (
            <>
            
              {team?.members?.slice(0, showAll ? drivers.length : 6).map((driver, index) => (
               
                <div key={index} style={{ display: 'flex', gap: 10, alignItems: 'center'}}>
                  
                  <FormControl sx={{ minWidth: '150px', maxWidth: '200px', minHeight: 56 }}>
                  {!(driver.user_id == team.owner_id ) ? <InputLabel>{driver.full_name}</InputLabel> : <Chip label={driver.full_name} icon={<ManageAccountsIcon sx={{marginRight: 5}}/>} variant='outlined' size='big' color='info' sx={{height:50}}/>}
                    {!(driver.user_id == team.owner_id ) && (
                      <Select
                        value={driverRoles[driver.full_name]}
                        defaultValue={driver.role}
                        onChange={(event) => handleChange(event, driver.full_name, driver.user_id)}
                        label={driver.full_name}
                        disabled={!isManager}
                      >
                        <MenuItem value="MGR">Manager</MenuItem>
                        <MenuItem value="DRV">Driver</MenuItem>
                      </Select>
                    )}
                  </FormControl>
                  {showRemoveMembers && isManager &&  !(driver.user_id == team.owner_id ) && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedMembers.includes(driver.user_id)}
                          onChange={() => handleMemberSelection(driver.user_id)}
                        />
                      }
                      
                    />
                  ) }
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={wantToRemove}
                  >
                    <Typography sx={{ fontSize: 24, top: -100, margin: "15px", color: 'WHITE', fontWeight: 'bold' }}>ARE YOU SURE?</Typography>
                    <Chip onClick={() => confirmRemoveMembers(driver.user_id)} sx={{ margin: "20px" }} label="REMOVE MEMBER" color='error' />
                    <Chip onClick={handleWantToRemove} label="BACK" color='info' />
                  </Backdrop>
                </div>
              ))}
            </>
          )}
        </div>
        {showRemoveMembers && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" color="error" onClick={handleWantToRemove}>Remove Members</Button>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} /> {/* This will push the settings section to the bottom */}
        <Box sx={{ maxWidth: 600, position: 'relative'}}>
          <Typography sx={{ fontSize: 24, marginBottom: 2, color: 'gray' }}>TEAM SETTINGS</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 16, marginBottom: 2 }}>
            {showChangeName && (
              <div className="change-name1" data-palette-mode="dark">
                <div className="frame">
                  <div className="text-wrapper">CHANGE TEAM NAME</div>
                  <TextField
                    hasDiv={false}
                    defaultValue={team.name}
                    onChange={handleNameChange}
                    className="text-field-instance"
                    label="Name"
                    variant="filled"
                    size="medium"
                  />
                </div>
                <CloseIcon
                  className="icon-button-instance"
                  sx={{ color: 'white', cursor: 'pointer' }}
                  onClick={changeTeamName}
                  size="medium"
                />
              </div>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontSize: 18, marginRight: 1 }}>Team Name:</Typography>
              <Typography sx={{ fontSize: 18, fontWeight: 100 }}>{team.name}</Typography>
            </Box>
            {!showChangeName && isManager && (
              <Button variant="text" color="primary" onClick={handleEditTeamName}>
                EDIT
              </Button>
            )}
          </Box>
          {team.isOwner && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 16 }}>
              <Typography sx={{ fontSize: 18 }}>Delete team</Typography>
              <Button onClick={handleWantToDelete} variant="text" color="error">
                DELETE
              </Button>
            </Box>
          )}

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wantToDelete}
          >
            <Typography sx={{ fontSize: 24, top: -100, margin: '15px', color: 'WHITE', fontWeight: 'bold' }}>
              ARE YOU SURE?
            </Typography>
            <Chip onClick={handleDeleteTeamClick} sx={{ margin: '20px' }} label="DELETE TEAM" color="error" />
            <Chip onClick={handleWantToDelete} label="BACK" color="info" />
          </Backdrop>
          {!team.isOwner && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 16 }}>
              <Typography sx={{ fontSize: 18 }}>Abandon team</Typography>
              <Button variant="text" onClick={handleWantToAbandon} color="error">
                ABANDON TEAM
              </Button>
            </Box>
          )}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wantToAbandon}
          >
            <Typography sx={{ fontSize: 24, top: -100, margin: '15px', color: 'WHITE', fontWeight: 'bold' }}>
              ARE YOU SURE?
            </Typography>
            <Chip sx={{ margin: '20px' }} onClick={() => handleDeleteMemberClick(parsedUserData.user_id)} label="ABANDON TEAM" color="error" />
            <Chip onClick={handleWantToAbandon} label="BACK" color="info" />
          </Backdrop>
        </Box>
        <Pricing open={openPrice} onClose={handlePricingClose} />
      </div>
    </>
  );
}
export default PageHeader;