import React, { useEffect, useState } from 'react';
import { isToday, isWithinInterval, isYesterday, subDays } from 'date-fns';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#121212',
            paper: '#1E1E1E',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#AAAAAA',
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
        h6: {
            fontWeight: 'bold',
            color: '#BBBBBB',
        },
    },
});

const StyledListItem = styled(ListItem)(({ theme }) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
        backgroundColor: '#333333',
    },
}));

const StyledListItemText = styled(ListItemText)({
    primary: {
        fontWeight: '500',
        color: '#FFFFFF',
    },
    secondary: {
        color: '#AAAAAA',
    },
});

const AnchorTemporaryDrawer = ({ onNewChat, handleChatSelection, isOpen, onClose }) => {
    const [historyChats, setHistoryChats] = useState([]);
    const [categorizeChat, setCategorizeChat] = useState({});

    const loadHistoryChat = async () => {
        const response = await fetch(`https://aimotor.eu/api/get_conversations/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        });

        let data = [];
        if (response?.ok) {
            data = await response.json();
        }
        setHistoryChats(data);
        setCategorizeChat(handleCategorizeChats(data));
        return data;
    };

    useEffect(() => {
        if (isOpen) {
            loadHistoryChat();
        }
    }, [isOpen]);

    const handleCategorizeChats = (chats) => {
        const today = [];
        const yesterday = [];
        const previous7Days = [];
        const previous30Days = [];

        const oneDayAgo = subDays(new Date(), 1);
        const sevenDaysAgo = subDays(new Date(), 7);
        const thirtyDaysAgo = subDays(new Date(), 30);

        chats.forEach(chat => {
            if (isToday(new Date(chat.last_interaction))) {
                today.push(chat);
            } else if (isYesterday(new Date(chat.last_interaction))) {
                yesterday.push(chat);
            } else if (isWithinInterval(new Date(chat.last_interaction), { start: sevenDaysAgo, end: oneDayAgo })) {
                previous7Days.push(chat);
            } else if (isWithinInterval(new Date(chat.last_interaction), { start: thirtyDaysAgo, end: oneDayAgo })) {
                previous30Days.push(chat);
            }
        });

        return { today, yesterday, previous7Days, previous30Days };
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Drawer anchor="right" open={isOpen} onClose={onClose}>
                <Box sx={{ width: 250, backgroundColor: '#1E1E1E', color: '#FFFFFF' }}>
                    <List>
                        <StyledListItem disablePadding button onClick={onNewChat}>
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                            <StyledListItemText primary="New Chat" />
                        </StyledListItem>
                    </List>
                    <Divider sx={{ backgroundColor: '#333333' }} />

                    {categorizeChat?.today?.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ padding: 2 }}>Today's Chats</Typography>
                            <List>
                                {categorizeChat.today.map(chat => (
                                    <StyledListItem key={chat._id} disablePadding button onClick={() => handleChatSelection(chat._id)}>
                                        <StyledListItemText primary={chat?.messages?.[0]?.message || "no messages"} />
                                    </StyledListItem>
                                ))}
                            </List>
                            <Divider sx={{ backgroundColor: '#333333' }} />
                        </>
                    )}
                    {categorizeChat?.yesterday?.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ padding: 2 }}>Yesterday's Chats</Typography>
                            <List>
                                {categorizeChat.yesterday.map(chat => (
                                    <StyledListItem key={chat._id} disablePadding button onClick={() => handleChatSelection(chat._id)}>
                                        <StyledListItemText primary={chat?.messages?.[0]?.message || "no messages"} />
                                    </StyledListItem>
                                ))}
                            </List>
                            <Divider sx={{ backgroundColor: '#333333' }} />
                        </>
                    )}
                    {categorizeChat?.previous7Days?.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ padding: 2 }}>Previous 7 days Chats</Typography>
                            <List>
                                {categorizeChat.previous7Days.map(chat => (
                                    <StyledListItem key={chat._id} disablePadding button onClick={() => handleChatSelection(chat._id)}>
                                        <StyledListItemText primary={chat?.messages?.[0]?.message || "no messages"} />
                                    </StyledListItem>
                                ))}
                            </List>
                            <Divider sx={{ backgroundColor: '#333333' }} />
                        </>
                    )}
                    {categorizeChat?.previous30Days?.length > 0 && (
                        <>
                            <Typography variant="h6" sx={{ padding: 2 }}>Previous 30 days Chats</Typography>
                            <List>
                                {categorizeChat.previous30Days.map(chat => (
                                    <StyledListItem key={chat._id} disablePadding button onClick={() => handleChatSelection(chat._id)}>
                                        <StyledListItemText primary={chat?.messages?.[0]?.message || "no messages"} />
                                    </StyledListItem>
                                ))}
                            </List>
                            <Divider sx={{ backgroundColor: '#333333' }} />
                        </>
                    )}
                    {categorizeChat?.today?.length === 0 && (
                        <Typography variant="h6" sx={{ padding: 2, textAlign: 'center' }}>No Chats Available</Typography>
                    )}
                </Box>
            </Drawer>
        </ThemeProvider>
    );
};

export default AnchorTemporaryDrawer;

