import { Button, Col, Container, Table } from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";
import React, { useEffect, useState } from "react";

import axios from "axios";
import myUserStore from "../stores/UserStore.js";
import { observer } from "mobx-react-lite"

const Control = () => {
    const [controlSessions, setControlSessions] = useState([]);
    const [hiddenDrivers, setHiddenDrivers] = useState([]);
    //const selectedTeam = JSON.parse(localStorage.getItem("selectedTeam"));
    const selectedTeam= myUserStore.getSelectedTeam
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `https://aimotor.eu/api/get_missing_sessions/?date=2024-02-08&team_id=${selectedTeam.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                            accept: "application/json",
                        },
                    }
                );
                setControlSessions(res.data); // Assuming your response contains an array of sessions
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);
    
    return (
        <Container>
            <div>
                <Table responsive striped bordered hover>
                    <thead>
                    <Col xs={12} className="mb-3">
            <h3>
             The following sessions have been added by drivers but not by managers:
            </h3>
          </Col>
                        <tr>
                            <th>Category</th>
                            <th>Session Number</th>
                    
                        </tr>
                    </thead>
                    <tbody>
                        {controlSessions.map(session => (
                            <tr key={session.id}>
                                <td>{session.category}</td>
                                <td>{session.session_number}</td>
                               
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </Container>
    );
};

export default observer (Control);

