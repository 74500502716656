import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, Stack, Switch, Typography } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import PayPalButton from '../PayPalButton';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaypalProvider from 'services/PaypalProvider';
import Plan from '../Plans/Plan';
import Plans from 'components/Pricing/Plans';
import { getToken } from 'services/PaypalHelper';
import myUserStore from 'stores/UserStore';
import { observer } from 'mobx-react-lite';

const pricingOptions = [
  {
    title: 'Growth',
    price: '$0/month',
    yearPrice: '$0/month',
    priceNumber: 0,
    features: [
      '1 user',
      '2 Active Projects',
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    title: 'Pro',
    price: '$10/month',
    yearPrice: '$120/year',
    priceNumber: 10,
    features: [
      '1 user',
      'Unlimited Projects',
      'Download Prototypes',
      'Remove Doov Branding',
      'Password Protection'
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
  {
    title: 'Team',
    price: '$30/month',
    yearPrice: '$360/year',
    priceNumber: 30,
    features: [
      'Unlimited Users',
      'Unlimited Projects',
      'Download Prototypes',
      'User roles and groups',
      'Password Protection'
    ],
    buttonColor: '#00CFFD',
    titleColor: '#000',
  },
];

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
  currency: "EUR",
  intent: "subscription",
  vault: true,
};


const PricingDialog = ({ open, onClose, showAll }) => {

  const dialogContentPanel = useRef(null)

  const onPlanClick = () => {
    setTimeout(() => {
      dialogContentPanel.current.scrollTop = dialogContentPanel.current.scrollHeight;
    }, 400)
  }

  const handleClose = (e) => {
    onClose(e)
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth PaperProps={{
      sx: {
        backgroundColor: '#002147',
        color: '#fff'
      }
    }}>
      <Box textAlign={'center'} position={'relative'} px={2}>
        <DialogTitle fontWeight={'bold'} sx={{ m: 0, py: 2 }}>
          UPGRADE YOUR PLAN
        </DialogTitle>
        <IconButton 
        color={'light'}
        sx={{
          position: 'absolute',
          right: '24px',
          top: '12px'
        }} onClick={handleClose}>
          <CloseIcon fontSize='large' />
        </IconButton>
      </Box>
      <DialogContent sx={{ px: 0 }} ref={dialogContentPanel}>
        <Plans onSuccess={handleClose} onPlanClick={onPlanClick} showAll={showAll} />
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'end' }}>
        <Link component={Button} href={'/racing-space/plans'} sx={{ marginLeft: 1, textTransform: 'uppercase' }} variant='text' color="light" onClick={handleClose}>
          manage your subscription
          <KeyboardArrowRightIcon />
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default observer(PricingDialog);


