

import React, { useState } from "react";
import RaceForm from "../RaceForm";
import RemoteForm from "../RemoteForm";
import DriverAnalyses from "../DriverAnalyses";
import HeroSectionExchangeData from "../HeroSectionExchangeData";
import ControlManager from "../ControlManager";
import { Container, Form, Col, Stack } from "react-bootstrap";
import { useEffect } from "react";
import axios from "axios";

import Activities from "../Activities";
import Drivers from "../Drivers";

import InsertData from "./InsertData";


const CombinedComponent = () => {
  const [selectedComponent, setSelectedComponent] = useState("");
  const [selectedRaceWeekend, setSelectedRaceWeekend] = useState("");
  const [userData, setUserData] = useState(null);
  const [competitions, setCompetitions] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState("");
  const [selectedCompetition, setSelectedCompetition] = useState(""); 
  const [selectedTrack, setSelectedTrack] = useState(""); // New state for track

  const handleSelect = (event) => {
    setSelectedComponent(event.target.value);
  };

  const handleRaceWeekendChange = (event) => {
    setSelectedRaceWeekend(event.target.value);
  };

  useEffect(() => {
    console.log("Fetching competitions...");
    axios
      .get(`https://aimotor.eu/api/get_competitions/`)
      .then((res) => {
        const competitions = res.data;
        console.log("Competitions data received:", competitions);
        setCompetitions(competitions);
        
        // Extract competition and track from the first competition data and set them in state
        if (competitions.length > 0) {
          setSelectedCompetition(competitions[0].id); // Set initial selected competition
          setSelectedTrack(competitions[0].track); // Set initial selected track
        }
      })
      .catch((error) => {
        console.error("Axios Error:", error);
      });
  }, []);

  useEffect(() => {
    console.log("Selected competition:", selectedCompetition);
    console.log("Selected track:", selectedTrack);
  }, [selectedCompetition, selectedTrack]);

  return (
    <Container>
      <Col xs={12} sm={6} md={4}>
        <Form.Group className="mb-3">
          <Form.Label>Select Race:</Form.Label>
          <Stack direction="horizontal" gap={1}>
            <Form.Select
              name="competition" // Change the name to competition
              value={selectedCompetition} // Use selectedCompetition state
              placeholder="Select Race"
              onChange={(e) => {
                setSelectedCompetition(e.target.value);
                // Find the track for the selected competition and set it
                const selectedCompetitionData = competitions.find(comp => comp.id === e.target.value);
                if (selectedCompetitionData) {
                  setSelectedTrack(selectedCompetitionData.track);
                }
              }} 
              required
            >
              <option value="">Select Race</option>
              {competitions.map((competition) => (
                <option
                  key={competition["competition"]}
                  value={competition["competition"]}
                >
                  {competition["competition"]} ({competition["track"]})
                </option>
              ))}
            </Form.Select>
          </Stack>
        </Form.Group>
      </Col>

      <Col xs={12} sm={6} md={4}>
        <Form.Group className="mb-3">
          <Form.Label>Select Tool:</Form.Label>
          <Form.Select
            value={selectedComponent}
            onChange={handleSelect}
            required
          >
            <option value="">Select Tool</option>
            {/* <option value="RaceForm">Show All The Sessions</option> */}
            <option value="RemoteForm">Show A Single Manche</option>
            <option value="DriverAnalyses">Analyse Single Driver</option>
            <option value="HeroSectionExchangeData">Control Insertions</option>
          </Form.Select>
        </Form.Group>
      </Col>

      <div>
        {/* Pass the selectedRaceWeekend as prop to the RaceForm component */}
        {/* {selectedComponent === "RaceForm" && <RaceForm raceWeekend={selectedRaceWeekend} />} */}
        {selectedComponent === "RemoteForm" && <RemoteForm />}
        {selectedComponent === "DriverAnalyses" && <DriverAnalyses raceWeekend={selectedRaceWeekend}/>}
        {selectedComponent === "HeroSectionExchangeData" && <HeroSectionExchangeData />}
      </div>
    </Container>
  );
};

export default CombinedComponent;
